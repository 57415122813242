<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">霞客后端云</el-breadcrumb-item>
        <el-breadcrumb-item>随机抽号</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-row :gutter="20" style="padding: 0 0 10px 0">
        <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
          <el-card v-loading="loading">
            <div slot="header">
              <span>抽号区</span>
              <el-button style="float: right; padding: 3px 0" type="text" @click="show_edit_dialog = true">设置</el-button>
            </div>
            <div class="wrap">
              <div class="show">{{Random_title}}</div><br>         
                <div class="btn1">
                  <a class="start" @click="btn1_ban === true ? null : random_mode.autostop === false ? rand() : autorand()">{{btn1_title}}</a>
                </div>
                <div class="btn2">
                  <a class="start" v-show="btn2_show" @click="rest">重置</a>
                </div>
            </div>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
          <el-card>
              <div slot="header">
                <span>结果区</span>
                <el-button style="float: right; padding: 3px 0" type="text" @click="exportToExcel" :disabled="disabled">导出数据</el-button>
              </div>
              <div :style="btn2_show == false ? 'position: relative;height:470px;' : 'position: relative;height:550px;'">
                <el-scrollbar style="height:500px">
                  <el-table :data="tableData.slice((currentPage - 1) * pagesize, currentPage * pagesize)" style="width: 100%">
                    <el-table-column prop="id" label="序号" header-align="center" align="center"></el-table-column>
                    <el-table-column prop="name" label="姓名" header-align="center" align="center"></el-table-column>
                  </el-table>
                </el-scrollbar>
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :pager-count="7"
                  :current-page="currentPage"
                  :page-sizes="[10,20,30,50]"
                  :page-size="pagesize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="tableData.length > 0 ? tableData.length : null"
                  background
                  style="position: absolute; bottom: 0; right: 0;"
                >
                </el-pagination>
              </div>
            </el-card>
        </el-col>
      </el-row>

      <el-dialog
        title="抽号设置"
        :visible.sync="show_edit_dialog"
        :before-close="handleClose"
        :width="create_width">
          <el-form ref="edit_form" status-icon :model="random_temp" label-width="90px">
            <el-form-item label="抽号名单" prop="title">
              <el-transfer v-model="random_temp.random_value" :data="random_data" 
                :titles="['不参与抽号', '参与抽号']" 
                :button-texts="['移出', '加入']"
                filterable
                :filter-method="filterMethod"
                filter-placeholder="请输入姓名查找"
                ></el-transfer>
            </el-form-item>
            <el-form-item label="自动暂停" prop="autostop">
              <el-switch v-model="random_temp.autostop"></el-switch><br>
              <span>开启后，每次点击开始抽号后，3秒后自动结束滚动并公布结果</span>
            </el-form-item>
            <el-form-item label="不重复抽取" prop="norepeat">
              <el-switch v-model="random_temp.norepeat"></el-switch><br>
              <span>开启后，将不会抽出重复的数据</span>
            </el-form-item>
          </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="handleEdit">保 存</el-button>
          <el-button @click="handleClose">取 消</el-button>
        </span>
      </el-dialog>
      
    </el-main>
  </el-main>
</template>

<style scoped>
    .breadcrumb_box{
      height: 40px;
      width: 100%;
      background-color: #f6f8f8;
      border-bottom: 1px solid #eee;
      padding: 15px;
      margin: 0;
      display: flex;
    }
    .breadcrumb_content{
      font-size: 15px;
      position: absolute;
      margin: auto;
      left: 40px;
    }
    .wrap{ float: left; /* 自适应内容宽度 */ position: relative; left: 50%; margin:30px auto; font-family:"微软雅黑";}
    .show{ position: relative; left: -50%; width:300px; height:300px; background-color:#ff3300; line-height:300px; text-align:center; color:#fff; font-size:50px; -moz-border-radius:150px; -webkit-border-radius:150px; border-radius:150px; background-image: -webkit-gradient(linear,0% 0%, 0% 100%, from(#61ffa4), to(#38ff8d), color-stop(0.5,#00d159)); -moz-box-shadow:2px 2px 10px #BBBBBB; -webkit-box-shadow:2px 2px 10px #BBBBBB; box-shadow:2px 2px 10px #BBBBBB;}
    .btn1 a{position: relative; left: -50%; display:block; width:120px; height:50px; margin:30px auto; text-align:center; line-height:50px; text-decoration:none; color:#fff; -moz-border-radius:25px; -webkit-border-radius:25px; border-radius:25px;}
    .btn1 a.start{ background:#42beff;}
    .btn1 a.start:hover{ background:#0097e6;}
    .btn2 a{position: relative; left: -50%; display:block; width:120px; height:50px; margin:30px auto; text-align:center; line-height:50px; text-decoration:none; color:#fff; -moz-border-radius:25px; -webkit-border-radius:25px; border-radius:25px;}
    .btn2 a.start{ background:#ffd527;}
    .btn2 a.start:hover{ background:#ffbb06;}
</style>
<style>
  .el-table .el-table__cell{
      padding: 10px 0 !important;
  }
</style>

<script>
  import axios from 'axios'
  import * as XLSX from 'xlsx'
  import { Loading } from 'element-ui'

  export default {
      name:'Random',
      data() {
        return {
          Random_title: "Ready",
          btn1_title: "开始抽号",
          btn1_ban: false,
          btn2_show: false,

          show_edit_dialog: false,

          isProcessing: null,

          create_width: '60%',

          random_data: [],

          random_temp:{
            autostop:false,
            norepeat:false,
            random_value: [],
          },

          random_mode:{
            autostop:false,
            norepeat:false,
            random_value: [],
          },

          disabled: false,

          table_index: 1,
          tableData: [],
          loading: true,

          filterMethod(query, item) {
            return item.label.indexOf(query) > -1;
          },

          people_list:[],

          currentPage: 1,  //默认初始页
          pagesize: 10,    //每页默认显示的数据
          pageCount: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改

        };
      },
      methods: {
        handleSizeChange: function (size) {
          this.pagesize = size;
        },
        //切换页码
        handleCurrentChange: function (currentPage) {
          this.currentPage = currentPage;
        },
        handleEdit() {
          this.show_edit_dialog = false;
          this.random_temp.random_value.sort((a, b) => parseInt(a) - parseInt(b));
          this.random_mode = JSON.parse(JSON.stringify(this.random_temp));
          this.people_list = [];
          let now_id = 0;
          for(let i=0;i<this.random_data.length;i++) {
            if(this.random_mode.random_value[now_id] == this.random_data[i].key) {
              this.people_list.push({id: now_id+1, name: this.random_data[i].label});
              now_id++;
            }
          }
        },
        handleClose() {
          this.show_edit_dialog = false
          this.random_temp = JSON.parse(JSON.stringify(this.random_mode))

        },
        indexMethod(index) {
          return this.table_index;
        },
        rand(){
          if(this.people_list.length == 0) {
            this.$message.error('已经没有符合要求的数据用于抽号了！');
            this.Random_title="N/A"
          }
          else {
            if (!this.isProcessing) {
              this.btn1_title = "停止"
              this.isProcessing = setInterval(() => {
                let randnum=Math.floor(Math.random()*this.people_list.length)
                this.Random_title = this.people_list[randnum].name
              }, 50)
            } else {
              clearInterval(this.isProcessing)
              this.isProcessing = null

              this.btn1_title = "开始抽号"

              let randnum=Math.floor(Math.random()*this.people_list.length)

              this.Random_title = this.people_list[randnum].name

              this.tableData.push({'id':this.table_index,'name':this.people_list[randnum].name})
              this.table_index++

              if(this.random_mode.norepeat == true) {
                this.people_list.splice(randnum, 1);
              }

              this.btn2_show = true
            }
          }
        },
        autorand(){
          if(this.people_list.length == 0) {
            this.$message.error('已经没有符合要求的数据用于抽号了！');
            this.Random_title="N/A"
          }
          else {
            this.btn1_ban = true

            this.isProcessing = setInterval(() => {
              let randnum=Math.floor(Math.random()*this.people_list.length)
              this.Random_title = this.people_list[randnum].name
            }, 50)

            let secends=3;
            this.btn1_title = secends;
            secends--;

            let daojishi = setInterval(() => {
              this.btn1_title = secends;
              secends--;
            },1000);

            setTimeout(()=>{
              clearInterval(daojishi)
              clearInterval(this.isProcessing)
              this.btn1_title = "开始抽号"

              this.isProcessing = null
              this.btn1_ban = false
              let randnum=Math.floor(Math.random()*this.people_list.length)

              this.Random_title = this.people_list[randnum].name

              this.tableData.push({'id':this.table_index,'name':this.people_list[randnum].name})
              this.table_index++

              if(this.random_mode.norepeat == true) {
                this.people_list.splice(randnum, 1);
              }

              this.btn2_show = true
            }, 3000)
          }
        },
        rest(){
          this.btn1_title = "开始抽号"
          this.Random_title="Ready"
          this.btn2_show = false
          this.tableData.length = 0
          this.table_index = 1
          if(this.random_mode.norepeat === true) {
            this.people_list = []
            let now_id=0;
            for(let i=0;i<this.random_data.length;i++) {
              if(this.random_mode.random_value[now_id] == this.random_data[i].key) {
                this.people_list.push({id: now_id+1, name: this.random_data[i].label});
                now_id++;
              }
            }
          }
          
        },
        getUserData() {
          axios.get('/api/getRandomData.php')
            .then(res => {
              if(res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if(res.data.msg != null) this.$message.error(res.data.msg);
              
              this.people_list = res.data.people_list
              for(let i=0;i<res.data.people_list.length;i++)
              {
                this.random_data.push({key:i+1, label:res.data.people_list[i].name})
                this.random_temp.random_value.push(i+1)
              }
              this.random_mode = JSON.parse(JSON.stringify(this.random_temp));
              this.loading = false
            }).catch(err => {
              console.log(err)
            })
        },
        exportToExcel() {
          if(this.tableData.length == 0) {
            this.$message.error('没有用于导出的数据');
          }
          else {
            this.disabled = true
            let loading = Loading.service({
              text: '数据生成中，请稍后...',
              background: 'rgba(0,0,0,.5)'
            })

            let arr = this.tableData.map(item => {
                return {
                  序号: item.id,
                  姓名: item.name
                }
              })
              

            const ws = XLSX.utils.json_to_sheet(arr)
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, '抽号结果')
            XLSX.writeFile(wb, `抽号结果${new Date().getTime()}.xlsx`)

            loading.close()
            this.disabled = false
          }
        },
        handleResize() {
          if (window.innerWidth < 992) {
            this.create_width = "100%"
          } else {
            this.create_width = "60%"
          }
        }
      },
      mounted() {
        this.handleResize()
        window.addEventListener("resize", this.handleResize);
      },
      created() {
        this.loading = true
        this.getUserData()
      },
      beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
      }
  };
</script>
