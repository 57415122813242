import { render, staticRenderFns } from "./Collect_file.vue?vue&type=template&id=2e222c10&scoped=true&"
import script from "./Collect_file.vue?vue&type=script&lang=js&"
export * from "./Collect_file.vue?vue&type=script&lang=js&"
import style0 from "./Collect_file.vue?vue&type=style&index=0&id=2e222c10&prod&scoped=true&lang=css&"
import style1 from "./Collect_file.vue?vue&type=style&index=1&id=2e222c10&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e222c10",
  null
  
)

export default component.exports