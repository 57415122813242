<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">霞客后端云</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/user/mytask' }">任务中心</el-breadcrumb-item>
        <el-breadcrumb-item>{{register_data.title}}-打卡区</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-card v-loading="loading">
        <div slot="header">
          <span style="font-size:19px;">{{register_data.title}}-打卡区</span>
        </div>
        <div style="font-size:20px;text-align:center;line-height:40px;">
          <h2>是否已经完成：{{register_data.title}}？</h2><br>
          <span v-if="register_data.if_finish === false">若完成请点击下方“我已完成”按钮！</span><br>
          姓名：{{ username }}<br>
          完成状态： <el-tag v-if="register_data.if_finish === true" type="success" size="medium" plain>已完成</el-tag>
                <el-tag v-if="register_data.if_finish === false" type="danger" size="medium" plain>未完成</el-tag><br><br>

          <el-button type="success" v-if="register_data.if_finish === false && register_data.status != 3 && register_data.status != 1" @click="finish">我已完成</el-button>
          <el-button type="danger" v-if="register_data.if_finish === false && (register_data.status == 3 || register_data.status == 1)">超时不可完成</el-button>
          
        </div>
        <el-divider content-position="left">发布者：{{register_data.poster}}</el-divider>

      </el-card>
    </el-main>
  </el-main>
</template>

<style scoped>
    .breadcrumb_box{
      height: 40px;
      width: 100%;
      background-color: #f6f8f8;
      border-bottom: 1px solid #eee;
      padding: 15px;
      margin: 0;
      display: flex;
    }
    .breadcrumb_content{
      font-size: 15px;
      position: absolute;
      margin: auto;
      left: 40px;
    }
    
</style>

<script>
  import axios from 'axios'
  export default {
      name:'Finish_register',
      data() {
          return {
            register_data:{},
            loading: true,
            username: localStorage.getItem('name')
          };
      },
      methods:{
        finish() {
          this.$confirm('确定已经完成了吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            axios.get('/api/RegisterController.php',{params:{method: 'set', id: this.$route.params.id}})
            .then(res => {
              if(res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if(res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);
              if(res.data.code == 200) {
                this.register_data.if_finish = true
                this.$message({
                  type: 'success',
                  message: '打卡成功!'
                });
              }
            }).catch(err => {
              console.log(err)
            })
          });
        },
        getRegisterData() {
          axios.get('/api/RegisterController.php',{params:{method: 'get', id: this.$route.params.id}})
            .then(res => {
              if(res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if(res.data.msg != null) this.$message.error(res.data.msg);
              this.register_data = res.data
              this.loading = false
            }).catch(err => {
              console.log(err)
            })
        }
      },
      created() {
        this.loading = true
        this.getRegisterData()
      }
  };
</script>
