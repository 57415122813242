<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">霞客后端云</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/user/liuyan' }">收到的留言</el-breadcrumb-item>
        <el-breadcrumb-item>{{tableData.title}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-card>
        <div v-loading="loading">
          <el-descriptions class="margin-top" :title="tableData.title" :column="3" size="" border>
            <template slot="extra">
              <el-button type="primary" @click="toLiuyan" size="small">返回</el-button>
            </template>
            <el-descriptions-item>
              <template slot="label"><i class="el-icon-location-outline"></i>标题</template>
              {{tableData.title}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"><i class="el-icon-user"></i>推送者</template>
              {{tableData.poster}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"><i class="el-icon-time"></i>推送时间</template>
              {{tableData.time}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"><i class="el-icon-tickets"></i>正文</template>
              {{tableData.content}}
            </el-descriptions-item>
          </el-descriptions>
        </div><br><br>
        <div v-if="tableData.reply == ''">
          <span style="text-align:left;font-size: 16px;font-weight: 700;">回复</span><br><br>
          <el-input
            type="textarea"
            placeholder="请输入回复的内容"
            v-model="reply"
            maxlength="100"
            rows="6"
            show-word-limit
          >
          </el-input><br><br>
          <center><el-button type="success" size="medium" @click="postReply">确认回复</el-button></center>
        </div>
        <div v-else>
          <span style="text-align:left;font-size: 16px;font-weight: 700;">我的回复</span><br><br>
          <span>{{tableData.reply}}</span>
        </div>
        
      </el-card>
    </el-main>
  </el-main>
</template>

<style scoped>
    .breadcrumb_box{
      height: 40px;
      width: 100%;
      background-color: #f6f8f8;
      border-bottom: 1px solid #eee;
      padding: 15px;
      margin: 0;
      display: flex;
    }
    .breadcrumb_content{
      font-size: 15px;
      position: absolute;
      margin: auto;
      left: 40px;
    }
    
</style>

<script>
  import axios from 'axios'
  export default {
      name:'Liuyan_Detailed',
      data() {
          return {
            reply: '',
            tableData: {},
            loading: true

          };
      },
      methods: {
        handleSizeChange: function (size) {
          this.pagesize = size;
        },
        //切换页码
        handleCurrentChange: function (currentPage) {
          this.currentPage = currentPage;
        },
        handleShow(id) {
          this.$router.push('/user/message/'+id)
        },
        toLiuyan() {
          this.$router.push('/user/Liuyan')
        },
        postReply() {
          if(this.reply == ''){
            this.$message.error('回复不可为空');
          } else {
            this.axios({
              method:"post",
              url: '/api/getLiuyanList.php?method=postReply&id=' + this.$route.params.id,
              data:{
                reply: this.reply
              },
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              },
            }).then((res)=>{
              if(res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if(res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);
              
              if(res.data.code == 200) {
                this.$message.success('回复成功！');
                this.tableData.reply = this.reply
              }else {
                this.$message.error(res.data.msg);
              }
            }).catch(err => {
              console.log(err)
            })
          }
          
        },
        getLiuyanDetailed() {
          axios.get('/api/getLiuyanList.php',{params:{method:'getContent', id:this.$route.params.id}})
            .then(res => {
              if(res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if(res.data.msg != null) this.$message.error(res.data.msg);
              
              this.tableData = res.data
              this.loading = false
            }).catch(err => {
              console.log(err)
            })
        },
      },
      created() {
        this.loading = true
        this.getLiuyanDetailed()
      }
  };
</script>
