<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">霞客后端云</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/user/register' }">登记表</el-breadcrumb-item>
        <el-breadcrumb-item>{{register_data.title}}-完成数据</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-row :gutter="20" style="padding: 0 0 10px 0">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card v-loading="loading">
            <div slot="header">
              <span>{{register_data.title}}-完成情况一览</span>
            </div>
            <div style="height:250px;">
              <el-tabs v-model="activeName">
                <el-tab-pane label="登记表信息" name="first" style="height:210px;">
                  <el-scrollbar style="height:95%">
                    <li class="list-group-item">
                      <b>登记表标题：</b>{{register_data.title}}
                    </li>
                    <li class="list-group-item">
                      <b>登记表是否公开：</b>
                      <el-tag type="primary" size="mini">{{register_data.is_public === true ? '公开' : '非公开'}}</el-tag>
                    </li>
                    <li class="list-group-item">
                      <b>登记表开放时间：</b>{{register_data.start_time}}
                    </li>
                    <li class="list-group-item">
                      <b>登记表关闭时间：</b>{{register_data.end_time}}
                    </li>
                    <li class="list-group-item">
                      <b>登记表状态：</b>
                      <el-tag v-if="register_data.status == 1" type="primary" size="mini" plain>未开始</el-tag>
                      <el-tag v-if="register_data.status == 2" type="success" size="mini" plain>进行中</el-tag>
                      <el-tag v-if="register_data.status == 3" type="danger" size="mini" plain>已结束不可提交</el-tag>
                      <el-tag v-if="register_data.status == 4" type="warning" size="mini" plain>已结束可提交</el-tag>
                    </li>
                  </el-scrollbar>
                </el-tab-pane>
                <el-tab-pane label="完成排行榜" name="second" style="height:210px;">
                  <el-scrollbar style="height:95%">
                    <el-table style="width: 100%" :data="tableData" :default-sort = "{prop: 'finish_rank', order: 'ascending'}">
                      <el-table-column prop="finish_rank" sortable label="排名" min-width="60px" header-align="center" align="center"></el-table-column>
                      <el-table-column prop="name" label="姓名" min-width="100px" header-align="center" align="center"></el-table-column>
                      <el-table-column prop="finish_time" label="完成时间" min-width="160px" header-align="center" align="center">
                        <template slot-scope="scope">
                          <span v-if="scope.row.finish_time!==''">{{scope.row.finish_time}}</span>
                          <span v-if="scope.row.finish_time===''" style="color: red;">未完成</span>
                        </template>
                      </el-table-column>
                    </el-table>
                    </el-scrollbar>
                </el-tab-pane>
                <el-tab-pane label="未完成名单" name="third">
                  <el-empty :image="all_finish_img" :image-size="100" description="恭喜恭喜！全部完成了哦~" v-if="ListArr.length === 0"></el-empty>
                  <el-tag size="small" type="danger" style="margin: 5px;" v-for="(item,index) in ListArr" :key="index">{{item.name}}</el-tag>
                </el-tab-pane>
                <el-tab-pane label="导出" name="forth">
                  <el-button type="success" @click="exportToExcel" :disabled="disabled" plain>数据导出</el-button>
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card v-loading="loading">
              <div slot="header">
                <span>{{register_data.title}}-完成情况图</span>
              </div>
              <div ref="finish_rate" style="width: 100%; height: 250px"></div>
            </el-card>
        </el-col>
      </el-row>
      <el-table v-loading="loading" :data="sortedArray.slice((currentPage - 1) * pagesize, currentPage * pagesize)" style="width: 100%">
        <el-table-column prop="id" label="号数" min-width="60px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="name" label="姓名" min-width="100px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="classid" label="学号" min-width="100px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="if_finish" label="完成状态" header-align="center" align="center">
          <template slot-scope="scope">
            <el-button v-if="scope.row.finish_time !== ''" type="success" size="mini" @click="handleUnConfirm(scope.row)" plain>已完成</el-button>
            <el-button v-if="scope.row.finish_time === ''" type="danger" size="mini" @click="handleConfirm(scope.row)" plain>未完成</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="finish_time" label="完成时间" min-width="160px" header-align="center" align="center"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :pager-count="7"
        :current-page="currentPage"
        :page-sizes="[10,20,30,50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length > 0 ? tableData.length : null"
        background
        style="float: right; margin-top: 20px"
      >
      </el-pagination>
    </el-main>
  </el-main>
</template>

<style scoped>
    .breadcrumb_box{
      height: 40px;
      width: 100%;
      background-color: #f6f8f8;
      border-bottom: 1px solid #eee;
      padding: 15px;
      margin: 0;
      display: flex;
    }
    .breadcrumb_content{
      font-size: 15px;
      position: absolute;
      margin: auto;
      left: 40px;
    }
    
</style>

<script>
  import axios from 'axios'
  import all_finish_icon from '@/assets/img/all_finish.png'
  import * as XLSX from 'xlsx'
  import { Loading } from 'element-ui'
  export default {
      name:'Showdata',
      data() {
          return {
            loading: true,
            activeName: 'first',
            keyWord: '',
            orderType: 0,
            register_data: {
              id: '',
              title: '',
              people_num: '',
              people_num_finish: ''
            },
            tableData: [],
            option: null,
            myChart: null,
            chart_data: [{value: 0, name: '已完成'},{value: 0, name: '未完成'}],
            all_finish_img: all_finish_icon,

            disabled: false,
          
            currentPage: 1,  //默认初始页
            pagesize: 10,    //每页默认显示的数据
            pageCount: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改

          };
      },
      methods: {
        handleSizeChange: function (size) {
          this.pagesize = size;
        },
        //切换页码
        handleCurrentChange: function (currentPage) {
          this.currentPage = currentPage;
        },


        handleConfirm(data) {
          this.$confirm('确定将其标记为已完成吗', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            axios.get('/api/RegisterController.php',{params:{method: 'setSingle', mode:'finish', id: this.$route.params.id, pid: data.id}})
              .then(res => {
                if(res.data.code != null && res.data.code == 400) {
                  this.$message.error('登录状态过期，请重新登录');
                  localStorage.clear('name');
                  localStorage.clear('isLogin');
                  this.$router.push('/login?refer=' + window.location.pathname)
                }
                else if(res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

                if(res.data.code == 200) {
                  for(let i = 0; i< this.tableData.length; i++) {
                    if(this.tableData[i].id == data.id) {
                      this.tableData[i].finish_time = res.data.finish_time;
                      break;
                    }
                  }
                  
                  this.chart_data[0].value = this.chart_data[0].value + 1
                  this.chart_data[1].value = this.register_data.people_num - this.chart_data[0].value

                  this.myChart.setOption(this.option);

                  this.$message({
                    type: 'success',
                    message: '成功标记：“'+data.name+'”为已完成！'
                  });
                } else {
                  this.$message({
                    type: 'error',
                    message: '操作失败！原因：'+res.data.msg
                  });
                }
              }).catch(err => {
                console.log(err)
              })
            
          }); 
        },
        handleUnConfirm(data) {
          this.$confirm('确定将其标记为未完成吗', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            axios.get('/api/RegisterController.php',{params:{method: 'setSingle', mode:'unfinish', id: this.$route.params.id, pid: data.id}})
              .then(res => {
                if(res.data.code != null && res.data.code == 400) {
                  this.$message.error('登录状态过期，请重新登录');
                  localStorage.clear('name');
                  localStorage.clear('isLogin');
                  this.$router.push('/login?refer=' + window.location.pathname)
                }
                else if(res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);
                
                if(res.data.code == 200) {
                  this.tableData[data.id-1].finish_time = ''

                  this.chart_data[0].value = this.chart_data[0].value - 1
                  this.chart_data[1].value = this.register_data.people_num - this.chart_data[0].value

                  this.myChart.setOption(this.option);
                  this.$message({
                    type: 'success',
                    message: '成功标记：“'+data.name+'”为未完成！'
                  });
                } else {
                  this.$message({
                    type: 'error',
                    message: '操作失败！原因：'+res.data.msg
                  });
                }
              }).catch(err => {
                console.log(err)
              })
          }); 
        },
        drawChart() {
          this.myChart = this.$echarts.init(this.$refs.finish_rate);
          this.option = {
            title: {
                text: '完成情况',
                left: 'center',
                top: 'center'
            },
            series: [
              {
                type: 'pie',
                data: this.chart_data,
                color: [
                  '#50C878',
                  '#fb7293'
                ],
                radius: ['40%', '70%']
              }
            ],
            tooltip: {
              show: true,
              trigger: 'item'
            }
          };
          this.myChart.setOption(this.option);
          window.addEventListener('resize', () => {
            this.myChart.resize()
          })
          
        },
        exportToExcel() {
          this.disabled = true
          let loading = Loading.service({
            text: '数据生成中，请稍后...',
            background: 'rgba(0,0,0,.5)'
          })

           let arr = this.sortedArray.map(item => {
              let wc;
              if(item.finish_time == "") wc = '未完成';
              else wc = '已完成';

              return {
                号数: item.id,
                学号: item.classid,
                姓名: item.name,
                完成状态: wc,
                完成时间: item.finish_time
              }
            })
            

          //const data = this.tableData // 表格数据
          const ws = XLSX.utils.json_to_sheet(arr)
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, ws, this.register_data.title)
          XLSX.writeFile(wb, `登记表-${this.register_data.title}数据${new Date().getTime()}.xlsx`)

          loading.close()
          this.disabled = false
        },
        getRegisterData() {
          axios.get('/api/RegisterController.php',{params:{method: 'getDetail', id: this.$route.params.id}})
            .then(res => {
              if(res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if(res.data.msg != null) this.$message.error(res.data.msg);
              
              this.register_data.id = res.data.id
              this.register_data.title = res.data.title
              this.register_data.people_num = res.data.people_num
              this.register_data.is_public = res.data.is_public
              this.register_data.start_time = res.data.start_time
              this.register_data.end_time = res.data.end_time
              this.register_data.status = res.data.status

              this.chart_data[0].value = res.data.people_num_finish
              this.chart_data[1].value = res.data.people_num - res.data.people_num_finish

              this.tableData = res.data.list
              this.pageCount = res.data.list.length

              this.myChart.setOption(this.option)
              this.loading = false
              
            }).catch(err => {
              console.log(err)
            })
        }
      },
      mounted(){
        this.drawChart();
      },
      created(){
        this.loading = true
        this.getRegisterData()
      },
      computed:{
        ListArr(){
            return this.tableData.filter(function(item){
              return item.finish_time === ''
            })
        },
        sortedArray() {
          return this.tableData.sort((a, b) => a.id - b.id)
        }
      }
  };
</script>
