<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">霞客后端云</el-breadcrumb-item>
        <el-breadcrumb-item>部门通讯录</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>

      <el-input v-model="inputContent" placeholder="请输入姓名或学号进行查询" prefix-icon="el-icon-search"
        style="width: 500px;"></el-input>&nbsp;
      <el-button icon="el-icon-search" type="primary" plain @click="searchput">搜索</el-button>
      <el-button icon="el-icon-download" type="success" plain @click="exportData">导出</el-button><br><br>

      <el-table v-loading="loading" :data="tables[0].slice((currentPage - 1) * pagesize, currentPage * pagesize)"
        style="width: 100%">
        <el-table-column prop="id" label="号数" min-width="60px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="name" label="姓名" min-width="100px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="classid" label="学号" min-width="120px" header-align="center"
          align="center"></el-table-column>
        <el-table-column prop="gender" label="性别" min-width="60px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="tel" label="电话" min-width="120px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="zhiwu" label="职务" min-width="120px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="control" label="操作" min-width="100px" header-align="center" align="center">
          <template slot-scope="scope">
            <el-button type="success" size="mini" @click="handleShow(scope.row)">详细资料</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :pager-count="7"
        :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper" :total="count" background style="float: right; margin-top: 20px">
      </el-pagination>
      <el-dialog title="详细资料" :visible.sync="show_digital_dialog" :width="create_width">
        <span>姓名：{{ show_digital_array.name }}</span><br>
        <span>性别：{{ show_digital_array.gender }}</span><br>
        <span>学号：{{ show_digital_array.classid }}</span><br>
        <span>号数：{{ show_digital_array.id }}</span><br>
        <span>入学年份：{{ show_digital_array.time }}</span><br>
        <span>学院：{{ show_digital_array.xueyuan }}</span><br>
        <span>专业班级：{{ show_digital_array.class }}</span><br>
        <span>电话：{{ show_digital_array.tel }}</span><br>
        <span>职务：{{ show_digital_array.zhiwu }}</span><br>
        <span>宿舍：{{ show_digital_array.sushe }}</span><br>
        <span>政治面貌：{{ show_digital_array.zzmm }}</span><br>

        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="show_digital_dialog = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-main>
  </el-main>
</template>

<style scoped>
.breadcrumb_box {
  height: 40px;
  width: 100%;
  background-color: #f6f8f8;
  border-bottom: 1px solid #eee;
  padding: 15px;
  margin: 0;
  display: flex;
}

.breadcrumb_content {
  font-size: 15px;
  position: absolute;
  margin: auto;
  left: 40px;
}
</style>

<script>
import axios from 'axios';
import * as XLSX from 'xlsx'
import { Loading } from 'element-ui'

export default {
  name: 'List',
  data() {
    return {
      loading: true,
      show_digital_array: [],
      show_digital_dialog: false,
      tableData: [],

      searchContent: '',
      inputContent: '',

      create_width: '50%',

      currentPage: 1,  //默认初始页
      pagesize: 10,    //每页默认显示的数据
      pageCount: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改

    };
  },
  methods: {
    handleSizeChange: function (size) {
      this.pagesize = size;
    },
    //切换页码
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
    },
    handleShow(row) {
      this.show_digital_dialog = true
      this.show_digital_array = row
    },

    searchput() {
      this.searchContent = this.inputContent
    },

    getList() {
      axios.get('/api/getList.php')
        .then(res => {
          if (res.data.code != null && res.data.code == 400) {
            this.$message.error('登录状态过期，请重新登录');
            localStorage.clear('name');
            localStorage.clear('isLogin');
            this.$router.push('/login?refer=' + window.location.pathname)
          }
          else if (res.data.msg != null) this.$message.error(res.data.msg);

          this.tableData = res.data
          this.pageCount = res.data.length
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
    },

    exportData() {
      this.disabled = true
      let loading = Loading.service({
        text: '数据生成中，请稍后...',
        background: 'rgba(0,0,0,.5)'
      })

      let arr = this.tables[0].map(item => {
        return {
          号数: item.id,
          学号: item.classid,
          姓名: item.name,
          性别: item.gender,
          电话: item.tel,
          职务: item.zhiwu,
          院系: item.xueyuan,
          班级: item.class,
          入学年份: item.time,
          专业班级: item.class,
          政治面貌: item.zzmm,
          宿舍: item.sushe
        }
      })


      //const data = this.tableData // 表格数据
      const ws = XLSX.utils.json_to_sheet(arr)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, '班级通讯录')
      XLSX.writeFile(wb, `通讯录-数据${new Date().getTime()}.xlsx`)

      loading.close()
      this.disabled = false
    },

    handleResize() {
      if (window.innerWidth < 992) {
        this.create_width = "100%"
      } else {
        this.create_width = "50%"
      }
    }
  },
  mounted() {
    this.handleResize()
    window.addEventListener("resize", this.handleResize);
  },
  created() {
    this.loading = true;
    this.getList();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    tables() {
      const search = this.searchContent;

      if (this.inputContent == '') {
        this.searchContent = ''
        this.currentPage = 1
        return [this.tableData, this.count = this.tableData.length];
      }
      if (search !== '') {
        return [this.tableData.filter((dataNews) => {
          return Object.keys(dataNews).some((key) => {
            return String(dataNews[key]).toLowerCase().indexOf(search) > -1;
          });
        }),
        this.count = this.tableData.filter((dataNews) => {
          return Object.keys(dataNews).some((key) => {
            return String(dataNews[key]).toLowerCase().indexOf(search) > -1;
          });
        }).length];
      }
      return [this.tableData, this.count = this.tableData.length];
    }
  }
};
</script>
