<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">霞客后端云</el-breadcrumb-item>
        <el-breadcrumb-item>我对部长有话说</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-button type="primary" @click="dialogVisible = true" plain>我要发留言</el-button>
      <el-table v-loading="loading" :data="tableData.slice((currentPage - 1) * pagesize, currentPage * pagesize)"
        style="width: 100%">
        <el-table-column prop="id" label="序号" min-width="60px" header-align="center" align="center"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" prop="title" label="留言标题" min-width="200px"></el-table-column>
        <el-table-column prop="toWho" label="留言对象" header-align="center" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.toWho == 1">李锦成</span>
            <span v-if="scope.row.toWho == 2">赖琮儒</span>
          </template>
        </el-table-column>
        <el-table-column prop="shiming" label="是否匿名" header-align="center" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.shiming == 1" type="primary" size="medium">实名</el-tag>
            <el-tag v-if="scope.row.shiming == 2" type="primary" size="medium">匿名</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="isRead" label="是否阅读" header-align="center" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isRead == 1" type="success" size="medium">已读</el-tag>
            <el-tag v-if="scope.row.isRead == 0" type="danger" size="medium">未读</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="留言时间" min-width="160px" header-align="center"
          align="center"></el-table-column>
        <el-table-column prop="control" label="操作" width="100px" header-align="center" align="center">
          <template slot-scope="scope">
            <el-button type="success" size="mini" @click="handleShow(scope.row, scope.row.unid)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :pager-count="7"
        :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper" :total="tableData.length > 0 ? tableData.length : null"
        background style="float: right; margin-top: 20px">
      </el-pagination>
      <el-dialog title="详细资料" :visible.sync="show_digital_dialog" :width="create_width">
        <span style="font-size:15px;">留言标题：{{ show_digital_array.title }}</span><br>
        <span style="font-size:15px;">留言内容：{{ show_digital_array.content }}</span><br>
        <span style="font-size:15px;">留言对象：
          <span v-if="show_digital_array.toWho == 1" style="font-size:15px;">李锦成</span>
          <span v-if="show_digital_array.toWho == 2" style="font-size:15px;">赖琮儒</span>
        </span><br>
        <span style="font-size:15px;">留言时间：{{ show_digital_array.time }}</span><br>
        <span style="font-size:15px;">是否匿名：
          <span v-if="show_digital_array.shiming == 1" style="font-size:15px;">实名</span>
          <span v-if="show_digital_array.shiming == 2" style="font-size:15px;">匿名</span>
        </span><br>
        <span style="font-size:15px;">是否阅读：
          <span v-if="show_digital_array.isRead == 1" style="font-size:15px;">已读</span>
          <span v-if="show_digital_array.isRead == 0" style="font-size:15px;">未读</span>
        </span><br>
        <span style="font-size:15px;">回复：{{ show_digital_array.reply }}</span><br>

        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="show_digital_dialog = false">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="留言" :visible.sync="dialogVisible" :width="create_width" :before-close="handleClose">
        <el-form ref="form" status-icon :model="form" :rules="rules" label-width="80px">
          <el-form-item label="留言对象" prop="toWho">
            <el-select v-model="form.toWho" placeholder="留言对象">
              <el-option label="李锦成" value="1"></el-option>
              <el-option label="赖琮儒" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标题" prop="title">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="内容" prop="content">
            <el-input type="textarea" :rows="10" v-model="form.content"></el-input>
          </el-form-item>
          <el-form-item label="是否实名" prop="shiming">
            <el-select v-model="form.shiming" placeholder="是否实名">
              <el-option label="实名留言" value="1"></el-option>
              <el-option label="匿名留言" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="验证码" prop="yz_code">
            <el-input v-model="form.yz_code" placeholder="请输入验证码（不区分大小写）">
              <template slot="append">
                <div @click="refreshCode">
                  <s-identify :identifyCode="identifyCode"></s-identify>
                </div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit('form')">发送留言</el-button>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
        </span>
      </el-dialog>
    </el-main>
  </el-main>
</template>

<style scoped>
.breadcrumb_box {
  height: 40px;
  width: 100%;
  background-color: #f6f8f8;
  border-bottom: 1px solid #eee;
  padding: 15px;
  margin: 0;
  display: flex;
}

.breadcrumb_content {
  font-size: 15px;
  position: absolute;
  margin: auto;
  left: 40px;
}
</style>

<script>
import SIdentify from '@/components/SIdentify'
import axios from 'axios'
export default {
  components: { SIdentify },
  name: 'Say',
  data() {
    var check_yz_code = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'));
      } else if (value !== this.identifyCode) {
        callback(new Error('验证码错误!'));
      } else {
        callback();
      }
    };
    return {
      loading: true,
      dialogVisible: false,
      show_digital_array: {},
      show_digital_dialog: false,
      identifyCode: '',
      identifyCodes: '1234567890abcdefjhijklinopqrsduvwxyz',
      tableData: [],

      create_width: '50',

      form: {
        toWho: '',
        title: '',
        content: '',
        shiming: '1',
        yz_code: ''
      },

      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ],
        yz_code: [
          { required: true, validator: check_yz_code, trigger: 'blur' }
        ],
        toWho: [
          { required: true, message: '请选择留言对象', trigger: 'change' }
        ],
        shiming: [
          { required: true, message: '请选择是否实名', trigger: 'change' }
        ]
      },

      currentPage: 1,  //默认初始页
      pagesize: 10,    //每页默认显示的数据
      pageCount: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改

    };
  },
  methods: {
    handleSizeChange: function (size) {
      this.pagesize = size;
    },
    //切换页码
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
    },
    handleShow(row, id) {
      console.log(row)
      this.show_digital_array = row
      //通过id从后端获取数据
      axios.get('/api/getMySay.php', { params: { method: 'getContent', id: id } })
        .then(res => {
          if (res.data.code != null && res.data.code == 400) {
            this.$message.error('登录状态过期，请重新登录');
            localStorage.clear('name');
            localStorage.clear('isLogin');
            this.$router.push('/login?refer=' + window.location.pathname)
          }
          else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);
          else {
            this.show_digital_array.content = res.data.content
            this.show_digital_array.reply = res.data.reply
            this.show_digital_dialog = true
          }
        }).catch(err => {
          this.$message.error(err)
        })
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.$refs['form'].resetFields();
          this.checkAll = false;
          this.isALL = false;
          this.dialogVisible = false;
          done();
        })
        .catch(_ => { });
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = new FormData();

          data.append('title', this.form.title);
          data.append('content', this.form.content);
          data.append('banwei', this.form.toWho);
          data.append('shiming', this.form.shiming);

          this.axios({
            method: "post",
            url: '/api/getMySay.php?method=setNew',
            data: data,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          })
            .then(res => {
              if (res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

              if (res.data.code == 200) {
                let new_data = res.data.new_data;
                this.$set(new_data, 'id', 1);

                this.tableData.forEach(item => {
                  item.id++;
                })

                this.tableData.unshift(new_data);
                this.$message.success('添加成功！');
                this.$refs[formName].resetFields();
                this.dialogVisible = false;
              }
              else {
                this.$message.error('留言失败！错误：' + res.data.msg);
              }
            }).catch(err => {
              console.log(err)
            })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    refreshCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)]
      }
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    getMySay() {
      axios.get('/api/getMySay.php')
        .then(res => {
          if (res.data.code != null && res.data.code == 400) {
            this.$message.error('登录状态过期，请重新登录');
            localStorage.clear('name');
            localStorage.clear('isLogin');
            this.$router.push('/login?refer=' + window.location.pathname)
          }
          else if (res.data.msg != null) this.$message.error(res.data.msg);

          this.tableData = res.data
          this.pageCount = res.data.length
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
    },
    handleResize() {
      if (window.innerWidth < 992) {
        this.create_width = "100%"
      } else {
        this.create_width = "50%"
      }
    }
  },
  mounted() {
    this.identifyCode = ''
    this.makeCode(this.identifyCodes, 4)

    this.handleResize()
    window.addEventListener("resize", this.handleResize);
  },
  created() {
    this.loading = true
    this.getMySay()
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>
