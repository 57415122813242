<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">霞客后端云</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/user/votemgr' }">投票管理</el-breadcrumb-item>
        <el-breadcrumb-item>{{ vote_data.title }}-投票数据</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-row :gutter="20" style="padding: 0 0 10px 0">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card v-loading="loading">
            <div slot="header">
              <span>{{ vote_data.title }}-完成情况一览</span>
            </div>
            <div style="height:250px;">
              <el-tabs v-model="activeName">
                <el-tab-pane label="投票信息" name="first" style="height:210px;">
                  <el-scrollbar style="height:95%">
                    <li class="list-group-item">
                      <b>投票标题：</b>{{ vote_data.title }}
                    </li>
                    <li class="list-group-item">
                      <b>投票开放时间：</b>{{ vote_data.start_time }}
                    </li>
                    <li class="list-group-item">
                      <b>投票关闭时间：</b>{{ vote_data.end_time }}
                    </li>
                    <li class="list-group-item">
                      <b>投票状态：</b>
                      <el-tag v-if="vote_data.status == 1" type="primary" size="mini" plain>未开始</el-tag>
                      <el-tag v-if="vote_data.status == 2" type="success" size="mini" plain>进行中</el-tag>
                      <el-tag v-if="vote_data.status == 3" type="danger" size="mini" plain>已结束</el-tag>
                    </li>
                  </el-scrollbar>
                </el-tab-pane>
                <el-tab-pane label="完成排行榜" name="second" style="height:210px;">
                  <el-scrollbar style="height:95%">
                    <el-table style="width: 100%" :data="tableData"
                      :default-sort="{ prop: 'finish_rank', order: 'ascending' }">
                      <el-table-column prop="finish_rank" sortable label="排名" min-width="60px" header-align="center"
                        align="center"></el-table-column>
                      <el-table-column prop="name" label="姓名" min-width="100px" header-align="center"
                        align="center"></el-table-column>
                      <el-table-column prop="finish_time" label="完成时间" min-width="160px" header-align="center"
                        align="center">
                        <template slot-scope="scope">
                          <span v-if="scope.row.finish_time !== ''">{{ scope.row.finish_time }}</span>
                          <span v-if="scope.row.finish_time === ''" style="color: red;">未完成</span>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-scrollbar>
                </el-tab-pane>
                <el-tab-pane label="未完成名单" name="third">
                  <el-empty :image="all_finish_img" :image-size="100" description="恭喜恭喜！全部完成了哦~"
                    v-if="ListArr.length === 0"></el-empty>
                  <el-tag size="small" type="danger" style="margin: 5px;" v-for="(item, index) in ListArr" :key="index">{{
                    item.name }}</el-tag>
                </el-tab-pane>
                <el-tab-pane label="导出" name="forth">
                  <el-button type="success" @click="exportToExcel" :disabled="disabled" plain>导出完成数据</el-button>
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card v-loading="loading">
            <div slot="header">
              <span>{{ vote_data.title }}-完成情况图</span>
            </div>
            <div ref="finish_rate" style="width: 100%; height: 250px"></div>
          </el-card>
        </el-col>
      </el-row>
      <el-card v-loading="loading">
        <div slot="header">
          <span>{{ vote_data.title }}-投票结果</span>
        </div>
        <el-row :gutter="20" style="padding: 0 0 10px 0">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div ref="vote_result_bar" style="width: 100%; height: 350px">
              <el-scrollbar style="height:95%">
                <el-table :data="vote_result_table_data" style="width: 100%">
                  <el-table-column prop="voter_name" :show-overflow-tooltip="true" label="参选者" header-align="center"
                    align="center" min-width="90px"></el-table-column>
                  <el-table-column prop="voter_num" :show-overflow-tooltip="true" sortable label="票数"
                    header-align="center" align="center" min-width="80px"></el-table-column>
                  <el-table-column prop="voter_rate" header-align="center" align="center" label="获票率" min-width="80px">
                    <template slot-scope="scope">
                      {{ scope.row.voter_rate + '%' }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="voter_rate" label="获票占比" min-width="150px">
                    <template slot-scope="scope">
                      <el-progress :show-text="false" :percentage="scope.row.voter_rate"></el-progress>
                    </template>
                  </el-table-column>
                </el-table>
              </el-scrollbar>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div ref="vote_result_pie" style="width: 100%; height: 350px"></div>
          </el-col>
        </el-row>
      </el-card>
      <el-table v-loading="loading" :data="sortedArray.slice((currentPage - 1) * pagesize, currentPage * pagesize)"
        style="width: 100%; margin-top: 10px;">
        <el-table-column prop="id" label="号数" min-width="60px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="name" label="姓名" min-width="100px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="classid" label="学号" min-width="100px" header-align="center"
          align="center"></el-table-column>
        <el-table-column prop="if_finish" label="完成状态" header-align="center" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.finish_time !== ''" type="success" size="medium" plain>已完成</el-tag>
            <el-tag v-if="scope.row.finish_time === ''" type="danger" size="medium" plain>未完成</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="finish_time" label="完成时间" min-width="160px" header-align="center"
          align="center"></el-table-column>
        <el-table-column label="操作" width="280px" header-align="center" align="center">
          <template slot-scope="scope">
            <el-button v-if="scope.row.finish_time === ''" type="danger" size="mini" plain>未提交</el-button>
            <el-button type="primary" size="mini" v-else-if="vote_data.isanonymous === false"
              @click="handleShowData(scope.row)" plain>查看数据</el-button>
            <el-button type="primary" size="mini" v-else-if="vote_data.isanonymous === true" :disabled="true"
              plain>无法查看数据</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :pager-count="7"
        :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper" :total="tableData.length > 0 ? tableData.length : null"
        background style="float: right; margin-top: 20px">
      </el-pagination>
    </el-main>
  </el-main>
</template>
  
<style scoped>
.breadcrumb_box {
  height: 40px;
  width: 100%;
  background-color: #f6f8f8;
  border-bottom: 1px solid #eee;
  padding: 15px;
  margin: 0;
  display: flex;
}

.breadcrumb_content {
  font-size: 15px;
  position: absolute;
  margin: auto;
  left: 40px;
}
</style>

<script>
import axios from 'axios'
import all_finish_icon from '@/assets/img/all_finish.png'
import * as XLSX from 'xlsx'
import { Loading } from 'element-ui'
export default {
  name: 'VoteData',
  data() {
    return {
      loading: true,
      activeName: 'first',
      keyWord: '',
      orderType: 0,
      vote_data: {
        id: '',
        title: '',
        people_num: '',
        people_num_finish: '',
        select_peoples: [],
        isanonymous: false,
        status: 1,
        start_time: '',
        end_time: ''
      },
      vote_result_table_data: [],
      tableData: [],
      option: null,
      myChart: null,
      myChartVoteChart: null,
      optionVote: null,
      chart_data: [{ value: 0, name: '已完成' }, { value: 0, name: '未完成' }],
      chart_vote_data: [],
      all_finish_img: all_finish_icon,

      disabled: false,

      currentPage: 1,  //默认初始页
      pagesize: 10,    //每页默认显示的数据
      pageCount: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改

    };
  },
  methods: {
    handleSizeChange: function (size) {
      this.pagesize = size;
    },
    //切换页码
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
    },
    handleShowData(data) {
      let resultArr = []
      for (let i = 0; i < data.peo_select_people.length; i++) {
        for (let j = 0; j < this.vote_data.select_peoples.length; j++) {
          if (this.vote_data.select_peoples[j].id == data.peo_select_people[i]) {
            resultArr.push(this.vote_data.select_peoples[j].name)
            break;
          }
        }
      }
      let resultStr = resultArr.join('，');


      this.$alert(data.name + '的投票结果为：<strong>' + resultStr + '</strong>', '投票结果', {
        confirmButtonText: '确定',
        dangerouslyUseHTMLString: true
      });
    },

    drawChart() {
      this.myChart = this.$echarts.init(this.$refs.finish_rate);
      this.option = {
        title: {
          text: '完成情况',
          left: 'center',
          top: 'center'
        },
        series: [
          {
            type: 'pie',
            data: this.chart_data,
            color: [
              '#50C878',
              '#fb7293'
            ],
            radius: ['40%', '70%']
          }
        ],
        tooltip: {
          show: true,
          trigger: 'item'
        }
      };
      this.myChart.setOption(this.option);
      window.addEventListener('resize', () => {
        this.myChart.resize()
      })

    },

    drawVoteChart() {
      this.myChartVoteChart = this.$echarts.init(this.$refs.vote_result_pie);
      this.optionVote = {
        legend: {
          top: 'bottom'
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: true },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        title: {
          text: '投票结果',
          left: 'center',
          top: 'top'
        },
        series: [
          {
            name: '投票结果',
            type: 'pie',
            radius: [30, 100],
            center: ['50%', '50%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 8
            },
            color: [
              '#5470c6',
              '#91cc75',
              '#fac858',
              '#ee6666',
              '#73c0de',
              '#3ba272',
              '#fc8452',
              '#9a60b4',
              '#ea7ccc'
            ],
            data: this.chart_vote_data,
          }
        ],
        tooltip: {
          show: true,
          trigger: 'item'
        }
      };
      this.myChartVoteChart.setOption(this.optionVote);
      window.addEventListener('resize', () => {
        this.myChartVoteChart.resize()
      })

    },
    exportToExcel() {
      this.disabled = true
      let loading = Loading.service({
        text: '数据生成中，请稍后...',
        background: 'rgba(0,0,0,.5)'
      })

      let arr = this.sortedArray.map(item => {
        let wc;
        if (item.finish_time == "") wc = '未完成';
        else wc = '已完成';

        return {
          号数: item.id,
          学号: item.classid,
          姓名: item.name,
          完成状态: wc,
          完成时间: item.finish_time
        }
      })


      //const data = this.tableData // 表格数据
      const ws = XLSX.utils.json_to_sheet(arr)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, this.vote_data.title)
      XLSX.writeFile(wb, `投票-${this.vote_data.title}数据${new Date().getTime()}.xlsx`)

      loading.close()
      this.disabled = false
    },
    getVoteData() {
      axios.get('/api/VoteController.php', { params: { method: 'getDetail', id: this.$route.params.id } })
        .then(res => {
          if (res.data.code != null && res.data.code == 400) {
            this.$message.error('登录状态过期，请重新登录');
            localStorage.clear('name');
            localStorage.clear('isLogin');
            this.$router.push('/login?refer=' + window.location.pathname)
          }
          else if (res.data.msg != null) this.$message.error(res.data.msg);

          this.vote_data.id = res.data.id
          this.vote_data.title = res.data.title
          this.vote_data.people_num = res.data.people_num
          this.vote_data.start_time = res.data.start_time
          this.vote_data.end_time = res.data.end_time
          this.vote_data.status = res.data.status
          this.vote_data.isanonymous = res.data.isanonymous
          this.vote_data.select_peoples = res.data.select_peoples
          this.vote_data.vote_result = res.data.vote_result
          this.vote_data.select_num = res.data.select_num

          for (let i = 0; i < res.data.select_peoples.length; i++) {
            this.vote_result_table_data.push({
              id: res.data.select_peoples[i].id,
              voter_name: res.data.select_peoples[i].name,
              voter_num: 0,
              voter_rate: 0
            })
            this.chart_vote_data.push({
              id: res.data.select_peoples[i].id,
              value: 0,
              name: res.data.select_peoples[i].name
            })
          }
          this.vote_result_table_data.push({
            id: 99999,
            voter_name: '未选票',
            voter_num: 0,
            voter_rate: 0
          })


          for (let i = 0; i < res.data.vote_result.length; i++) {
            if (res.data.vote_result[i].voteId == '') {
              for (let j = 0; j < this.vote_result_table_data.length; j++) {
                if (this.vote_result_table_data[j].id == 99999) {
                  this.vote_result_table_data[j].voter_num = res.data.vote_result[i].value * res.data.select_num;
                  this.vote_result_table_data[j].voter_rate = Math.trunc(((res.data.vote_result[i].value * res.data.select_num) / (res.data.people_num * res.data.select_num)).toFixed(4) * 10000) / 10000 * 100;
                  break;
                }
              }
            }

            for (let j = 0; j < this.vote_result_table_data.length; j++) {
              if (this.vote_result_table_data[j].id == res.data.vote_result[i].voteId) {
                this.vote_result_table_data[j].voter_num = res.data.vote_result[i].value;
                this.vote_result_table_data[j].voter_rate = Math.trunc((res.data.vote_result[i].value / (res.data.people_num * res.data.select_num)).toFixed(4) * 10000) / 10000 * 100
                break;
              }
            }

            for (let j = 0; j < this.chart_vote_data.length; j++) {
              if (this.chart_vote_data[j].id == res.data.vote_result[i].voteId) {
                this.chart_vote_data[j].value = res.data.vote_result[i].value;
                break;
              }
            }

          }

          this.myChartVoteChart.setOption(this.optionVote);

          this.vote_result_table_data.sort((a, b) => a.id - b.id);


          this.chart_data[0].value = res.data.people_num_finish
          this.chart_data[1].value = res.data.people_num - res.data.people_num_finish

          this.tableData = res.data.list
          this.pageCount = res.data.list.length

          this.myChart.setOption(this.option)
          this.loading = false

        }).catch(err => {
          console.log(err)
        })
    }
  },
  mounted() {
    this.drawChart();
    this.drawVoteChart();
  },
  created() {
    this.loading = true
    this.getVoteData()
  },
  computed: {
    ListArr() {
      return this.tableData.filter(function (item) {
        return item.finish_time === ''
      })
    },
    sortedArray() {
      return this.tableData.sort((a, b) => a.id - b.id)
    }
  }
};
</script>
  