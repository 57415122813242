<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">霞客后端云</el-breadcrumb-item>
        <el-breadcrumb-item>任务中心</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="全部任务" name="first">
          <el-table v-loading="loading" :data="tableData.slice((currentPage - 1) * pagesize, currentPage * pagesize)"
            style="width: 100%">
            <el-table-column prop="id" label="序号" width="100px" header-align="center" align="center"></el-table-column>
            <el-table-column prop="title" :show-overflow-tooltip="true" label="任务标题" width="300px">
              <template slot-scope="scope">
                {{ scope.row.title }}
                <span v-show="scope.row.ifshenhe === true && scope.row.leixing == 2" class="shenhe_class">审</span>
                <span v-show="scope.row.isneed === false && scope.row.leixing == 2" class="need_class">选</span>
              </template>
            </el-table-column>
            <el-table-column prop="if_finish" label="是否完成" header-align="center" align="center">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.if_finish === true" type="success" size="medium" plain>已完成</el-tag>
                <el-tag v-if="scope.row.if_finish === false" type="danger" size="medium" plain>未完成</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="leixing" label="任务类别" header-align="center" align="center">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.leixing == 1" type="primary" size="medium" plain>登记表</el-tag>
                <el-tag v-if="scope.row.leixing == 2" type="warning" size="medium" plain>收集表</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" width="140px" header-align="center" align="center">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.status == 1" type="primary" size="medium" plain>未开始</el-tag>
                <el-tag v-if="scope.row.status == 2" type="success" size="medium" plain>进行中</el-tag>
                <el-tag v-if="scope.row.status == 3" type="danger" size="medium" plain>已结束不可提交</el-tag>
                <el-tag v-if="scope.row.status == 4" type="warning" size="medium" plain>已结束可提交</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="poster" label="发布人" header-align="center" align="center"></el-table-column>
            <el-table-column prop="post_time" label="发布时间" width="160px"></el-table-column>
            <el-table-column label="操作" width="100px" header-align="center" align="center">
              <template slot-scope="scope">
                <el-button size="mini" type="info" v-if="scope.row.status === 1">不可完成</el-button>
                <el-button size="mini" type="success" v-else-if="scope.row.if_finish === true"
                  @click="scope.row.leixing == 1 ? toRegister(scope.row.unid) : toCollect(scope.row.unid)">查看详情</el-button>
                <el-button size="mini" type="info" v-else-if="scope.row.status === 3">不可完成</el-button>
                <el-button size="mini" type="danger" v-else-if="scope.row.if_finish === false"
                  @click="scope.row.leixing == 1 ? toRegister(scope.row.unid) : toCollect(scope.row.unid)">前往完成</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :pager-count="7"
            :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper" :total="tableData.length > 0 ? tableData.length : null"
            background style="float: right; margin-top: 20px">
          </el-pagination>
        </el-tab-pane>

        <el-tab-pane label="登记表" name="second">
          <el-table :data="RegisterArr.slice((currentPage_reg - 1) * pagesize_reg, currentPage_reg * pagesize_reg)"
            style="width: 100%">
            <el-table-column prop="id" label="序号" width="100px" header-align="center" align="center"></el-table-column>
            <el-table-column prop="title" :show-overflow-tooltip="true" label="任务标题" width="300px"></el-table-column>
            <el-table-column prop="if_finish" label="是否完成" header-align="center" align="center">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.if_finish === true" type="success" size="medium" plain>已完成</el-tag>
                <el-tag v-if="scope.row.if_finish === false" type="danger" size="medium" plain>未完成</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="leixing" label="任务类别" header-align="center" align="center">
              <template slot-scope="">
                <el-tag type="primary" size="medium" plain>登记表</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" width="140px" header-align="center" align="center">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.status == 1" type="primary" size="medium" plain>未开始</el-tag>
                <el-tag v-if="scope.row.status == 2" type="success" size="medium" plain>进行中</el-tag>
                <el-tag v-if="scope.row.status == 3" type="danger" size="medium" plain>已结束不可提交</el-tag>
                <el-tag v-if="scope.row.status == 4" type="warning" size="medium" plain>已结束可提交</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="poster" label="发布人" header-align="center" align="center"></el-table-column>
            <el-table-column prop="post_time" label="发布时间" width="160px"></el-table-column>
            <el-table-column label="操作" width="100px" header-align="center" align="center">
              <template slot-scope="scope">
                <el-button size="mini" type="info" v-if="scope.row.status === 1">不可完成</el-button>
                <el-button size="mini" type="success" v-else-if="scope.row.if_finish === true"
                  @click="scope.row.leixing == 1 ? toRegister(scope.row.unid) : toCollect(scope.row.unid)">查看详情</el-button>
                <el-button size="mini" type="info" v-else-if="scope.row.status === 3">不可完成</el-button>
                <el-button size="mini" type="danger" v-else-if="scope.row.if_finish === false"
                  @click="scope.row.leixing == 1 ? toRegister(scope.row.unid) : toCollect(scope.row.unid)">前往完成</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange_reg" @current-change="handleCurrentChange_reg" :pager-count="7"
            :current-page="currentPage_reg" :page-sizes="[10, 20, 30, 50]" :page-size="pagesize_reg"
            layout="total, sizes, prev, pager, next, jumper" :total="RegisterArr.length > 0 ? RegisterArr.length : null"
            background style="float: right; margin-top: 20px">
          </el-pagination>
        </el-tab-pane>

        <el-tab-pane label="收集表" name="third">
          <el-table :data="CollectArr.slice((currentPage_col - 1) * pagesize_col, currentPage_col * pagesize_col)"
            style="width: 100%">
            <el-table-column prop="id" label="序号" width="100px" header-align="center" align="center"></el-table-column>
            <el-table-column prop="title" :show-overflow-tooltip="true" label="任务标题" width="300px"></el-table-column>
            <el-table-column prop="if_finish" label="是否完成" header-align="center" align="center">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.if_finish === true" type="success" size="medium" plain>已完成</el-tag>
                <el-tag v-if="scope.row.if_finish === false" type="danger" size="medium" plain>未完成</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="leixing" label="任务类别" header-align="center" align="center">
              <template slot-scope="">
                <el-tag type="warning" size="medium" plain>收集表</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" width="140px" header-align="center" align="center">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.status == 1" type="primary" size="medium" plain>未开始</el-tag>
                <el-tag v-if="scope.row.status == 2" type="success" size="medium" plain>进行中</el-tag>
                <el-tag v-if="scope.row.status == 3" type="danger" size="medium" plain>已结束不可提交</el-tag>
                <el-tag v-if="scope.row.status == 4" type="warning" size="medium" plain>已结束可提交</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="poster" label="发布人" header-align="center" align="center"></el-table-column>
            <el-table-column prop="post_time" label="发布时间" width="160px"></el-table-column>
            <el-table-column label="操作" width="100px">
              <template slot-scope="scope">
                <el-button size="mini" type="info" v-if="scope.row.status === 1">不可完成</el-button>
                <el-button size="mini" type="success" v-else-if="scope.row.if_finish === true"
                  @click="scope.row.leixing == 1 ? toRegister(scope.row.unid) : toCollect(scope.row.unid)">查看详情</el-button>
                <el-button size="mini" type="info" v-else-if="scope.row.status === 3">不可完成</el-button>
                <el-button size="mini" type="danger" v-else-if="scope.row.if_finish === false"
                  @click="scope.row.leixing == 1 ? toRegister(scope.row.unid) : toCollect(scope.row.unid)">前往完成</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange_col" @current-change="handleCurrentChange_col" :pager-count="7"
            :current-page="currentPage_col" :page-sizes="[10, 20, 30, 50]" :page-size="pagesize_col"
            layout="total, sizes, prev, pager, next, jumper" :total="CollectArr.length > 0 ? CollectArr.length : null"
            background style="float: right; margin-top: 20px">
          </el-pagination>
        </el-tab-pane>
      </el-tabs>

    </el-main>
  </el-main>
</template>

<style scoped>
.breadcrumb_box {
  height: 40px;
  width: 100%;
  background-color: #f6f8f8;
  border-bottom: 1px solid #eee;
  padding: 15px;
  margin: 0;
  display: flex;
}

.breadcrumb_content {
  font-size: 15px;
  position: absolute;
  margin: auto;
  left: 40px;
}

.shenhe_class {
  background-color: #fdf6ec;
  border: 1px solid #e6a23c;
  color: #e6a23c;
  font-size: 12px;
  border-radius: 3px;
  margin-right: 3px;
}

.need_class {
  background-color: #ecf3fd;
  border: 1px solid #3c80e6;
  color: #3c80e6;
  font-size: 12px;
  border-radius: 3px;
  margin-right: 3px;
}
</style>

<script>
import axios from 'axios'
export default {
  name: 'Mytask',
  data() {
    return {
      activeName: 'first',
      tableData: [],

      loading: true,

      currentPage: 1,  //默认初始页
      pagesize: 10,    //每页默认显示的数据
      pageCount: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改

      currentPage_reg: 1,  //默认初始页
      pagesize_reg: 10,    //每页默认显示的数据
      pageCount_reg: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改

      currentPage_col: 1,  //默认初始页
      pagesize_col: 10,    //每页默认显示的数据
      pageCount_col: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改

    };
  },
  methods: {
    handleSizeChange: function (size) {
      this.pagesize = size;
    },
    //切换页码
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
    },
    handleSizeChange_reg: function (size) {
      this.pagesize_reg = size;
    },
    //切换页码
    handleCurrentChange_reg: function (currentPage) {
      this.currentPage_reg = currentPage;
    },
    handleSizeChange_col: function (size) {
      this.pagesize_col = size;
    },
    //切换页码
    handleCurrentChange_col: function (currentPage) {
      this.currentPage_col = currentPage;
    },
    toRegister(id) {
      this.$router.push('/user/finish_register/' + id)
    },
    toCollect(id) {
      this.$router.push('/user/collect_file/' + id)
    },
    getTaskList() {
      axios.get('/api/getTaskList.php')
        .then(res => {
          if (res.data.code != null && res.data.code == 400) {
            this.$message.error('登录状态过期，请重新登录');
            localStorage.clear('name');
            localStorage.clear('isLogin');
            this.$router.push('/login?refer=' + window.location.pathname)
          }
          else if (res.data.msg != null) this.$message.error(res.data.msg);

          this.tableData = res.data.tasklist
          this.pageCount = res.data.tasklist.length
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
    }
  },
  created() {
    this.loading = true;
    this.getTaskList();
  },
  computed: {
    RegisterArr() {
      return this.tableData.filter(function (item) {
        return item.leixing == 1
      })
    },
    CollectArr() {
      return this.tableData.filter(function (item) {
        return item.leixing == 2
      })
    }
  }
};
</script>
