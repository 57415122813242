<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">霞客后端云</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/user/collect' }">收集表</el-breadcrumb-item>
        <el-breadcrumb-item>{{ collect_data.title }}-完成数据</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-row :gutter="20" style="padding: 0 0 10px 0">
        <el-col :span="12" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card v-loading="loading">
            <div slot="header">
              <span>{{ collect_data.title }}-完成情况一览</span>
            </div>
            <div style="height:250px;">
              <el-tabs v-model="activeName">
                <el-tab-pane label="收集表信息" name="first" style="height:210px;">
                  <el-scrollbar style="height:95%">
                    <li class="list-group-item">
                      <b>收集表标题：</b>{{ collect_data.title }}
                    </li>
                    <li class="list-group-item">
                      <b>收集表开放时间：</b>{{ collect_data.start_time }}
                    </li>
                    <li class="list-group-item">
                      <b>收集表关闭时间：</b>{{ collect_data.end_time }}
                    </li>
                    <li class="list-group-item">
                      <b>收集表状态：</b>
                      <el-tag v-if="collect_data.status == 1" type="primary" size="mini" plain>未开始</el-tag>
                      <el-tag v-if="collect_data.status == 2" type="success" size="mini" plain>进行中</el-tag>
                      <el-tag v-if="collect_data.status == 3" type="danger" size="mini" plain>已结束不可提交</el-tag>
                      <el-tag v-if="collect_data.status == 4" type="warning" size="mini" plain>已结束可提交</el-tag>
                    </li>
                  </el-scrollbar>
                </el-tab-pane>
                <el-tab-pane label="完成排行榜" name="second" style="height:230px;">
                  <el-scrollbar style="height:95%">
                    <el-table style="width: 100%" :data="tableData"
                      :default-sort="{ prop: 'finish_rank', order: 'ascending' }">
                      <el-table-column prop="finish_rank" sortable label="排名" min-width="60px" header-align="center"
                        align="center"></el-table-column>
                      <el-table-column prop="name" label="姓名" min-width="100px" header-align="center"
                        align="center"></el-table-column>
                      <el-table-column prop="finish_time" label="完成时间" min-width="160px" header-align="center"
                        align="center">
                        <template slot-scope="scope">
                          <span v-if="scope.row.finish_time !== ''">{{ scope.row.finish_time }}</span>
                          <span v-if="scope.row.finish_time === ''" style="color: red;">未完成</span>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-scrollbar>
                </el-tab-pane>
                <el-tab-pane label="未完成名单" name="third">
                  <el-empty :image="all_finish_img" :image-size="100" description="恭喜恭喜！全部完成了哦~"
                    v-if="ListArr.length === 0"></el-empty>
                  <el-tag size="small" type="danger" style="margin: 5px;" v-for="(item, index) in ListArr" :key="index">{{
                    item.name }}</el-tag>
                </el-tab-pane>
                <el-tab-pane label="导出" name="forth">
                  <el-button type="success" @click="exportToExcel" :disabled="disabled" plain>导出完成数据</el-button>
                  <el-button type="primary" @click="exportZIP" :disabled="disabled_zip" plain>打包导出</el-button>
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card v-loading="loading">
            <div slot="header">
              <span>{{ collect_data.title }}-完成情况图</span>
            </div>
            <div ref="finish_rate" style="width: 100%; height: 250px"></div>
          </el-card>
        </el-col>
      </el-row>
      <el-table v-loading="loading" :data="sortedArray.slice((currentPage - 1) * pagesize, currentPage * pagesize)"
        style="width: 100%">
        <el-table-column prop="id" label="号数" min-width="60px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="name" label="姓名" min-width="100px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="classid" label="学号" min-width="100px" header-align="center"
          align="center"></el-table-column>
        <el-table-column prop="if_finish" label="完成状态" header-align="center" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.finish_time !== ''" type="success" size="medium" plain>已完成</el-tag>
            <el-tag v-if="scope.row.finish_time === ''" type="danger" size="medium" plain>未完成</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="shenhe" label="审核状态" header-align="center" align="center"
          v-if="collect_data.ifshenhe === true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.finish_time === ''" type="info" size="medium" plain>未提交</el-tag>
            <el-tag v-else-if="scope.row.shenhe == 0" type="warning" size="medium" plain>待审核</el-tag>
            <el-tag v-else-if="scope.row.shenhe == 1" type="success" size="medium" plain>审核通过</el-tag>
            <el-tag v-else-if="scope.row.shenhe == 2" type="danger" size="medium" plain>审核驳回</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="finish_time" label="完成时间" min-width="160px" header-align="center"
          align="center"></el-table-column>
        <el-table-column label="操作" width="280px" header-align="center" align="center">
          <template slot-scope="scope">
            <el-button v-if="scope.row.finish_time !== ''" type="success" size="mini" plain
              @click="handleDownload(scope.row.id)">下载</el-button>
            <el-button v-if="scope.row.finish_time === ''" type="danger" size="mini" plain>未提交</el-button>
            <el-button type="primary" size="mini" @click="handleShowAdminUpload(scope.row.classid)" plain>上传</el-button>
            <el-button type="warning" size="mini" v-if="scope.row.finish_time !== '' && collect_data.ifshenhe === true" @click="shenhe(scope.row.classid)"
              plain>审核</el-button>
            <el-button type="info" size="mini" v-if="scope.row.finish_time !== '' && collect_data.ifshenhe === true" @click="removeData(scope.row)"
              plain>清除</el-button>
            <el-button type="warning" size="mini" v-if="scope.row.finish_time !== '' && collect_data.ifshenhe === false" @click="removeData(scope.row)"
              plain>清除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :pager-count="7"
        :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper" :total="tableData.length > 0 ? tableData.length : null"
        background style="float: right; margin-top: 20px">
      </el-pagination>

      <el-dialog title="上传文件" :visible.sync="dialogVisible" :width="create_width" :before-close="handleClose">
        <div style="text-align: center;">该操作将会覆盖用户原来上传的版本</div>
        <el-upload class="upload-demo" drag ref="upload" action="" :before-upload="beforeUpload" :on-change="handleChange"
          :on-remove="handleRemove" :on-exceed="handleExceed" :multiple="false" :accept="collect_data.ext"
          :file-list="fileList" :auto-upload="false" :limit="1">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip"><span style="font-size:13px;color:red;">允许提交的文件后缀：{{
            collect_data.ext
          }}</span></div>
        </el-upload>
        <div v-show="progressFlag">
          <el-progress :text-inside="true" :stroke-width="14" :percentage="progressPercent"
            status="success"></el-progress>
        </div>
        <div style="text-align: center;">
          <el-button v-show="post_btn" style="margin: 10px 0;" icon="el-icon-upload2" size="medium" type="primary"
            :loading="uploading" @click="my_upload">
            确认上传
          </el-button><br>
          <el-button style="margin-left: 0" icon="el-icon-circle-close" size="medium" @click="handleClose">
            关闭窗口
          </el-button>
        </div>
      </el-dialog>
      <el-dialog title="上传文件" :visible.sync="dialogVisibleShenhe" :width="create_width">
        <div style="text-align: center;">请选择更新的审核状态</div><br>
        <div style="text-align: center;">
          <el-button type="success" size="medium" @click="acceptShenhe" plain>审核通过</el-button>
          <el-button type="danger" size="medium" @click="rejectShenhe" plain>审核驳回</el-button>
        </div>

      </el-dialog>
    </el-main>
  </el-main>
</template>

<style scoped>
.breadcrumb_box {
  height: 40px;
  width: 100%;
  background-color: #f6f8f8;
  border-bottom: 1px solid #eee;
  padding: 15px;
  margin: 0;
  display: flex;
}

.breadcrumb_content {
  font-size: 15px;
  position: absolute;
  margin: auto;
  left: 40px;
}

.upload-demo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
<style>
.el-upload input {
  display: none !important;
}

.el-table .el-table__cell {
  padding: 6px 0 !important;
}
</style>


<script>
import axios from 'axios'
import all_finish_icon from '@/assets/img/all_finish.png'
import * as XLSX from 'xlsx'
import { Loading } from 'element-ui'
export default {
  name: 'Showcollect',
  data() {
    return {
      loading: true,
      activeName: 'first',
      collect_data: {},
      tableData: [],
      option: null,
      myChart: null,
      chart_data: [{ value: 0, name: '已完成' }, { value: 0, name: '未完成' }],
      post_btn: true,
      uploading: false,
      progressFlag: false,
      progressPercent: 10,
      dialogVisible: false,
      dialogVisibleShenhe: false,
      fileList: [],
      all_finish_img: all_finish_icon,

      disabled: false,
      disabled_zip: false,

      upload_selected_classid: '',
      shenhe_selected_classid: '',

      create_width: '40%',

      currentPage: 1,  //默认初始页
      pagesize: 10,    //每页默认显示的数据
      pageCount: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改

    };
  },
  methods: {
    handleSizeChange: function (size) {
      this.pagesize = size;
    },
    //切换页码
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
    },
    handleClose(done) {
      if (this.post_btn === true) {
        this.$confirm('确认关闭？')
          .then(_ => {
            this.dialogVisible = false;
            this.$refs['upload'].clearFiles();
            this.progressFlag = false;
            this.fileList = [];
            done();
          })
          .catch(_ => { });
      }
      else {
        this.dialogVisible = false;
        this.$refs['upload'].clearFiles();
        this.progressFlag = false;
        this.fileList = [];
        this.progressPercent = 10;
        this.post_btn = true;
        this.upload_selected_classid = '';
      }

    },
    handleShowAdminUpload(classid) {
      this.upload_selected_classid = classid
      this.dialogVisible = true

    },
    beforeUpload(file) {
      let size = file.size / 1024 / 1024;
      if (size > 50) {
        this.$message.warning("文件大小不得超过50M");
      }
    },
    handleChange(file, fileList) {
      if (file) {
        this.fileList = fileList.slice(-3);
      }
    },
    handleRemove(file, fileList) {
      this.fileList = [];
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        '只能选择1个文件，如果要重新选择文件，请先在上传列表中删除原来的文件'
      );
    },
    handleDownload(pid) {
      this.$message.success('成功发起下载请求，请耐心等待文件下载完成');
      let a = document.createElement('a')
      a.href = '/api/CollectController.php?method=downloadSingle&id=' + this.$route.params.id + '&pid=' + pid
      a.click();
    },
    acceptShenhe() {
      axios.get('/api/CollectController.php', { params: { method: 'handleShenhe', mode: 'accept', id: this.$route.params.id, classid: this.shenhe_selected_classid} })
        .then(res => {
          if (res.data.code != null && res.data.code == 400) {
            this.$message.error('登录状态过期，请重新登录');
            localStorage.clear('name');
            localStorage.clear('isLogin');
            this.$router.push('/login?refer=' + window.location.pathname)
          }
          else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

          if (res.data.code == 200) {
            for (let i = 0; i < this.tableData.length; i++) {
              if (this.tableData[i].classid == this.shenhe_selected_classid) {
                this.tableData[i].shenhe = 1;
                break;
              }
            }

            this.$message({
              type: 'success',
              message: '成功更新为审核通过！'
            });
          } else {
            this.$message({
              type: 'error',
              message: '操作失败！原因：' + res.data.msg
            });
          }
          this.dialogVisibleShenhe = false

        }).catch(err => {
          console.log(err)
        })
    },
    rejectShenhe() {
      axios.get('/api/CollectController.php', { params: { method: 'handleShenhe', mode: 'reject', id: this.$route.params.id, classid: this.shenhe_selected_classid} })
        .then(res => {
          if (res.data.code != null && res.data.code == 400) {
            this.$message.error('登录状态过期，请重新登录');
            localStorage.clear('name');
            localStorage.clear('isLogin');
            this.$router.push('/login?refer=' + window.location.pathname)
          }
          else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

          if (res.data.code == 200) {
            for (let i = 0; i < this.tableData.length; i++) {
              if (this.tableData[i].classid == this.shenhe_selected_classid) {
                this.tableData[i].shenhe = 2;
                break;
              }
            }

            this.$message({
              type: 'success',
              message: '成功更新为审核驳回！'
            });
            this.dialogVisibleShenhe = false
          } else {
            this.$message({
              type: 'error',
              message: '操作失败！原因：' + res.data.msg
            });
          }
        }).catch(err => {
          console.log(err)
        })
    },
    my_upload() {
      if (this.fileList.length === 0) {
        this.$message.warning("请点击上传文件，添加文件");
      }
      else {
        let form = new FormData();
        let batch_file = this.fileList[0].raw;
        form.append('file', batch_file);
        let that = this
        that.progressFlag = true
        this.axios({
          method: 'post',
          headers: {
            "Content-Type": "multipart/form-data"
          },
          url: '/api/CollectController.php',
          params: { method: 'upload', mode: 'admin', id: this.$route.params.id, classid: this.upload_selected_classid },
          timeout: 180000,
          data: form,
          onUploadProgress: progressEvent => {
            that.progressPercent = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(0) | 0
          }
        }).then((res) => {
          if (res.data.code != null && res.data.code == 400) {
            this.$message.error('登录状态过期，请重新登录');
            localStorage.clear('name');
            localStorage.clear('isLogin');
            this.$router.push('/login?refer=' + window.location.pathname)
          }
          else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

          if (res.data.code == 200 && that.progressPercent === 100) {
            this.post_btn = false
            this.collect_data.if_finish = true

            this.tableData.forEach(item => {
              if (item.classid == this.upload_selected_classid) {
                item.finish_time = res.data.time
              }
            })

            this.chart_data[0].value = this.chart_data[0].value + 1
            this.chart_data[1].value = this.collect_data.people_num - this.chart_data[0].value

            this.myChart.setOption(this.option);

            this.upload_selected_classid = ''

            this.$message.success('上传完成');

          } else {
            this.$message.error('上传失败！' + res.data.msg);
          }
        }).catch((err) => {
          this.$message.error(err);
        })
      }

    },
    drawChart() {
      this.myChart = this.$echarts.init(this.$refs.finish_rate);
      this.option = {
        title: {
          text: '完成情况',
          left: 'center',
          top: 'center'
        },
        series: [
          {
            type: 'pie',
            data: this.chart_data,
            color: [
              '#50C878',
              '#fb7293'
            ],
            radius: ['40%', '70%']
          }
        ],
        tooltip: {
          show: true,
          trigger: 'item'
        }
      };
      this.myChart.setOption(this.option);
      window.addEventListener('resize', () => {
        this.myChart.resize()
      })
    },
    shenhe(classid) {
      this.shenhe_selected_classid = classid
      this.dialogVisibleShenhe = true
    },
    removeData(formdata) {
      if (formdata.if_finish === "") {
        this.$message.error('该用户还没有完成该收集表，无法清除记录~')
        return false
      }
      this.$confirm('此操作将清除' + formdata.name + '的提交记录及文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.get('/api/CollectController.php', { params: { method: 'clear', id: this.$route.params.id, classid: formdata.classid } })
          .then(res => {
            if (res.data.code != null && res.data.code == 400) {
              this.$message.error('登录状态过期，请重新登录');
              localStorage.clear('name');
              localStorage.clear('isLogin');
              this.$router.push('/login?refer=' + window.location.pathname)
            }
            else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

            if (res.data.code == 200) {
              this.$message({
                type: 'success',
                message: '已成功清除' + formdata.name + '的提交记录及文件'
              });
              this.tableData.forEach(item => {
                if (item.classid == formdata.classid) {
                  item.finish_time = ''
                }
              })
              this.chart_data[0].value = this.chart_data[0].value - 1
              this.chart_data[1].value = this.collect_data.people_num - this.chart_data[0].value

              this.myChart.setOption(this.option);
            } else {
              this.$message.error('失败：' + res.data.msg)
            }
          }).catch(err => {
            console.log(err)
          })
      })
    },
    exportToExcel() {
      this.disabled = true
      let loading = Loading.service({
        text: '数据生成中，请稍后...',
        background: 'rgba(0,0,0,.5)'
      })

      let arr = this.sortedArray.map(item => {
        let wc;
        if (item.finish_time == "") wc = '未完成';
        else wc = '已完成';

        let sh;
        if (item.shenhe == 0) sh = '待审核';
        else if (item.shenhe == 1) sh = '审核通过';
        else if (item.shenhe == 2) sh = '审核驳回';

        if(this.collect_data.ifshenhe === false) sh = '无需审核';



        return {
          号数: item.id,
          学号: item.classid,
          姓名: item.name,
          完成状态: wc,
          完成时间: item.finish_time,
          审核状态: sh
        }
      })


      //const data = this.tableData // 表格数据
      const ws = XLSX.utils.json_to_sheet(arr)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, this.collect_data.title)
      XLSX.writeFile(wb, `收集表-${this.collect_data.title}数据${new Date().getTime()}.xlsx`)

      loading.close()
      this.disabled = false
    },
    exportZIP() {
      this.disabled_zip = true
      let loading = Loading.service({
        text: '服务器打包中，请稍后...',
        background: 'rgba(0,0,0,.5)'
      })

      axios.get('/api/CollectController.php', { params: { method: 'createZIP', id: this.$route.params.id }, timeout: 60000 })
        .then(res => {
          if (res.data.code != null && res.data.code == 400) {
            this.$message.error('登录状态过期，请重新登录');
            localStorage.clear('name');
            localStorage.clear('isLogin');
            this.$router.push('/login?refer=' + window.location.pathname)
          }

          if (res.data.code == 200) {
            loading.close()
            this.disabled_zip = false

            this.$message.success('成功发起下载请求，请耐心等待文件下载完成');
            let a = document.createElement('a')
            a.href = '/api/CollectController.php?method=downloadZIP&key=' + res.data.key
            a.click();
          }
        })
        .catch(err => {
          this.$message.err(err)
        })
    },
    getCollectData() {
      axios.get('/api/CollectController.php', { params: { method: 'getDetail', id: this.$route.params.id } })
        .then(res => {
          if (res.data.code != null && res.data.code == 400) {
            this.$message.error('登录状态过期，请重新登录');
            localStorage.clear('name');
            localStorage.clear('isLogin');
            this.$router.push('/login?refer=' + window.location.pathname)
          }
          else if (res.data.msg != null) this.$message.error(res.data.msg);

          this.collect_data.id = res.data.id
          this.collect_data.title = res.data.title
          this.collect_data.people_num = res.data.people_num

          this.collect_data.start_time = res.data.start_time
          this.collect_data.end_time = res.data.end_time
          this.collect_data.status = res.data.status

          this.collect_data.ifshenhe = res.data.ifshenhe

          this.collect_data.ext = res.data.ext

          this.chart_data[0].value = res.data.people_num_finish
          this.chart_data[1].value = res.data.people_num - res.data.people_num_finish

          this.tableData = res.data.list
          this.pageCount = res.data.list.length

          this.myChart.setOption(this.option)
          this.loading = false

        }).catch(err => {
          console.log(err)
        })
    },
    handleResize() {
      if (window.innerWidth < 992) {
        this.create_width = "100%"
      } else {
        this.create_width = "40%"
      }
    }
  },
  mounted() {
    this.drawChart();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  created() {
    this.loading = true
    this.getCollectData()
  },
  computed: {
    ListArr() {
      return this.tableData.filter(function (item) {
        return item.finish_time === ''
      })
    },
    sortedArray() {
      return this.tableData.sort((a, b) => a.id - b.id)
    }
  }
};
</script>
