<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">霞客后端云</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/user/message' }">消息中心</el-breadcrumb-item>
        <el-breadcrumb-item>{{tableData.title}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-card v-loading="loading">
        <div>
          <el-descriptions class="margin-top" :title="tableData.title" :column="3" size="" border>
            <template slot="extra">
              <el-button type="primary" @click="toMessage" size="small">返回</el-button>
            </template>
            <el-descriptions-item>
              <template slot="label"><i class="el-icon-coordinate"></i>标题</template>
              {{tableData.title}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"><i class="el-icon-user"></i>推送者</template>
              {{tableData.poster}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"><i class="el-icon-location-outline"></i>推送时间</template>
              {{tableData.time}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"><i class="el-icon-tickets"></i>正文</template>
              <div v-html="tableData.content"></div>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </el-card>
    </el-main>
  </el-main>
</template>

<style scoped>
    .breadcrumb_box{
      height: 40px;
      width: 100%;
      background-color: #f6f8f8;
      border-bottom: 1px solid #eee;
      padding: 15px;
      margin: 0;
      display: flex;
    }
    .breadcrumb_content{
      font-size: 15px;
      position: absolute;
      margin: auto;
      left: 40px;
    }
    
</style>

<script>
  import axios from 'axios'
  export default {
      name:'Message_Detailed',
      data() {
          return {
            tableData: {},
            loading: true

          };
      },
      methods: {
        toMessage() {
          this.$router.push('/user/message')
        },
        getMessageDetailed() {
          axios.get('/api/getMessage.php',{params:{method:'getContent', id:this.$route.params.id}})
            .then(res => {
              if(res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if(res.data.msg != null) this.$message.error(res.data.msg);
              
              if(res.data.code == 200) {
                this.tableData = res.data
              }
              else this.$message.error('获取失败！原因：' + res.data.msg)
              this.loading = false
            }).catch(err => {
              console.log(err)
            })
        },
      },
      created() {
        this.loading = true
        this.getMessageDetailed()
      }
  };
</script>
