import Vue from 'vue'
import Router from 'vue-router'
import Login from '../views/Login'
import user from '../views/user'
import test from '../views/test'
import UserCenter from '../views/UserCenter'
import Personal from '../views/Personal'
import Document from '../views/Document'
import Class from '../views/Class'
import Register from '../views/Register'
import Showdata from '../views/Showdata'
import Collect from '../views/Collect'
import Showcollect from '../views/Showcollect'
import Mytask from '../views/Mytask'
import Finish_register from '../views/Finish_register'
import Collect_file from '../views/Collect_file'
import Tuanyuan from '../views/Tuanyuan'
import Shenhe from '../views/Shenhe'
import List from '../views/List'
import Random from '../views/Random'
import Say from '../views/Say'
import Fee from '../views/Fee'
import Baoxiao from '../views/Baoxiao'
import UserSettings from '../views/UserSettings'
import Message from '../views/Message'
import Message_Detailed from '../views/Message_Detailed'
import Liuyan from '../views/Liuyan'
import Liuyan_Detailed from '../views/Liuyan_Detailed'
import qx from '../views/qx'
import Notice from '../views/Notice'
import QQLogin from '../views/QQLogin'
import MyScore from '../views/MyScore'
import MyReport from '../views/MyReport'
import Vote from '../views/vote'
import VotePage from '../views/votePage'
import Votemgr from '../views/Votemgr'
import VoteData from '../views/VoteData'
import NotFound from '../views/error'
import AIStudentImage from '../views/testAIStuImage'
import AIStudentOffering from '../views/testAIOffering'
import CareerPlanningTable from "@/views/CareerPlanningTable.vue";
import CareerPlanning from "@/views/CareerPlanning.vue";

Vue.use(Router)

export default new Router({
    mode:'history',
    routes:[
        {
            path:'/',
            name:'Login',
            component:Login,
            meta: {
                title: '霞客后端云',
            }
        },
        {
            path:'/login',
            name:'Login',
            component:Login,
            meta: {
                title: '霞客后端云-登录',
            }
        },
        {
            path:'/qqlogin',
            name:'QQLogin',
            component:QQLogin,
            meta: {
                title: '霞客后端云',
            }
        },
        {
            path:'/testPage',
            name:'userTest',
            component:user,
            meta: {
                title: '霞客后端云',
            },
            children: [
                {
                    path:'/testPage/AIStudentImage',
                    name:'AIStudentImage',
                    component:AIStudentImage,
                    meta: {
                        title: '霞客后端云-学生画像',
                    }
                },
                {
                    path:'/testPage/AIStudentOffering',
                    name:'AIStudentOffering',
                    component:AIStudentOffering,
                    meta: {
                        title: '霞客后端云-就业推荐',
                    }
                },
            ]
        },
        {
            path:'/user',
            name:'user',
            component:user,
            meta: {
                title: '霞客后端云',
            },
            children: [
                {
                    path:'/user/UserCenter',
                    name:'UserCenter',
                    component:UserCenter,
                    meta: {
                        title: '霞客后端云-首页',
                    }
                },
                {
                    path:'/user/personal',
                    name:'Personal',
                    component:Personal,
                    meta: {
                        title: '霞客后端云-个人荣誉',
                    }
                },
                {
                    path:'/user/document',
                    name:'Document',
                    component:Document,
                    meta: {
                        title: '霞客后端云-个人履历',
                    }
                },
                {
                    path:'/user/class',
                    name:'Class',
                    component:Class,
                    meta: {
                        title: '霞客后端云-班级荣誉',
                    }
                },
                {
                    path:'/user/register',
                    name:'Register',
                    component:Register,
                    meta: {
                        title: '霞客后端云-登记表',
                    }
                },
                {
                    path:'/user/showdata/:id',
                    name:'Showdata',
                    component:Showdata,
                    meta: {
                        title: '霞客后端云-登记表数据',
                    }
                },
                {
                    path:'/user/collect',
                    name:'Collect',
                    component:Collect,
                    meta: {
                        title: '霞客后端云-收集表',
                    }
                },
                {
                    path:'/user/showcollect/:id',
                    name:'Showcollect',
                    component:Showcollect,
                    meta: {
                        title: '霞客后端云-收集表数据',
                    }
                },
                {
                    path:'/user/mytask',
                    name:'Mytask',
                    component:Mytask,
                    meta: {
                        title: '霞客后端云-任务中心',
                    }
                },
                {
                    path:'/user/finish_register/:id',
                    name:'Finish_register',
                    component:Finish_register,
                    meta: {
                        title: '霞客后端云-打卡',
                    }
                },
                {
                    path:'/user/collect_file/:id',
                    name:'Collect_file',
                    component:Collect_file,
                    meta: {
                        title: '霞客后端云-收集表提交区',
                    }
                },
                {
                    path:'/user/ty',
                    name:'Tuanyuan',
                    component:Tuanyuan,
                    meta: {
                        title: '霞客后端云-团员列表',
                    }
                },
                {
                    path:'/user/shenhe',
                    name:'Shenhe',
                    component:Shenhe,
                    meta: {
                        title: '霞客后端云-报销审核',
                    }
                },
                {
                    path:'/user/list',
                    name:'List',
                    component:List,
                    meta: {
                        title: '霞客后端云-班级通讯录',
                    }
                },
                {
                    path:'/user/random',
                    name:'Random',
                    component:Random,
                    meta: {
                        title: '霞客后端云-随机抽号',
                    }
                },
                {
                    path:'/user/say',
                    name:'Say',
                    component:Say,
                    meta: {
                        title: '霞客后端云-我对班委有话说',
                    }
                },
                {
                    path:'/user/fee',
                    name:'Fee',
                    component:Fee,
                    meta: {
                        title: '霞客后端云-班费明细',
                    }
                },
                {
                    path:'/user/baoxiao',
                    name:'Baoxiao',
                    component:Baoxiao,
                    meta: {
                        title: '霞客后端云-申请报销',
                    }
                },
                {
                    path:'/user/UserSettings',
                    name:'UserSettings',
                    component:UserSettings,
                    meta: {
                        title: '霞客后端云-信息修改',
                    }
                },
                {
                    path:'/user/message',
                    name:'Message',
                    component:Message,
                    meta: {
                        title: '霞客后端云-消息中心',
                    }
                },
                {
                    path:'/user/message/:id',
                    name:'Message_Detailed',
                    component:Message_Detailed,
                    meta: {
                        title: '霞客后端云-消息详情',
                    }
                },
                {
                    path:'/user/liuyan',
                    name:'Liuyan',
                    component:Liuyan,
                    meta: {
                        title: '霞客后端云-收到的留言',
                    }
                },
                {
                    path:'/user/liuyan/:id',
                    name:'Liuyan_Detailed',
                    component:Liuyan_Detailed,
                    meta: {
                        title: '霞客后端云-留言详情',
                    }
                },
                {
                    path:'/user/notice',
                    name:'Notice',
                    component:Notice,
                    meta: {
                        title: '霞客后端云-公告管理',
                    }
                },
                {
                    path:'/user/qx',
                    name:'qx',
                    component:qx,
                    meta: {
                        title: '霞客后端云-账号管理',
                    }
                },
                {
                    path:'/user/myScore',
                    name:'MyScore',
                    component:MyScore,
                    meta: {
                        title: '霞客后端云-我的成绩',
                    }
                },
                {
                    path:'/user/myReport/:id',
                    name:'MyReport',
                    component:MyReport,
                    meta: {
                        title: '霞客后端云-我的成绩',
                    }
                },
                {
                    path:'/user/vote',
                    name:'Vote',
                    component:Vote,
                    meta: {
                        title: '霞客后端云-投票',
                    }
                },
                {
                    path:'/user/vote/:id',
                    name:'VotePage',
                    component:VotePage,
                    meta: {
                        title: '霞客后端云-投票区',
                    }
                },
                {
                    path:'/user/votemgr',
                    name:'Votemgr',
                    component:Votemgr,
                    meta: {
                        title: '霞客后端云-投票管理',
                    }
                },
                {
                    path:'/user/votedata/:id',
                    name:'VoteData',
                    component:VoteData,
                    meta: {
                        title: '霞客后端云-投票数据',
                    }
                },
                {
                    path:'/user/CareerReport',
                    name:'CareerPlanningTable',
                    component:CareerPlanningTable,
                    meta: {
                        title: '霞客后端云-职业生涯规划',
                    }
                },
                {
                    path:'/user/CareerReport/:id',
                    name:'CareerPlanning',
                    component:CareerPlanning,
                    meta: {
                        title: '霞客后端云-职业生涯规划报告',
                    }
                }
            ]
        },
        {
            path:'*',
            name:'NotFound',
            component:NotFound,
            meta: {
                title: '404 Not Found',
            }
        },
    ]
})