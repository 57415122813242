<template>
  <el-main style="padding:0px;">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">霞客后端云</el-breadcrumb-item>
        <el-breadcrumb-item>首页</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9">
          <el-row>
            <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9">
              <div class="user_info_box" v-loading="userinfo_loading">
                <div style="text-align: center;">
                  <img src="../assets/img/default_face.png" class="avatar_img">
                  <br>
                  <span>{{ username }}</span>
                  <br>
                  <br>
                  <el-tag type="warning" effect="dark" size="small">{{ class_name }}</el-tag><br><br>
                  <el-tag type="" effect="dark" size="small" v-if="usergroup != 3">{{ zhiwu }}</el-tag><span v-if="usergroup != 3">&nbsp;</span>
                  <el-tag type="success" effect="dark" size="small">{{ usergroup == 1 ? '超级管理员' : usergroup == 2 ? '班级管理员'
                  : usergroup == 4 ? '学科管理员' : '普通成员'}}</el-tag><br><br><br>
                  <el-badge :value="unread_num" class="item" v-if="unread_num != 0">
                    <el-button type="info" size="medium" @click="toRouter('/user/message')" plain>消息中心</el-button>
                  </el-badge>
                  <el-button v-else type="info" size="medium" @click="toRouter('/user/message')" plain>消息中心</el-button>

                  <br><br>
                  <el-button type="primary" size="medium" @click="toRouter('/user/personal')"
                    plain>个人荣誉</el-button><br><br>
                  <el-button type="success" size="medium" @click="toRouter('/user/document')"
                    plain>个人履历</el-button><br><br>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15">
              <div class="user_info_box" style="padding:15px" v-loading="adminPanelLoading">
                <el-scrollbar style="height:97%">
                  <el-collapse v-model="tool_box">
                    <el-collapse-item name="1" v-if="usergroup != 3">
                      <template slot="title">
                        管理员工作台&nbsp;&nbsp;<el-tag type="success" size="small">{{ zhiwu }}</el-tag>
                      </template>
                      <div>
                        <el-tabs v-model="banwei_tool">
                          <el-tab-pane label="任务管理" name="taskmgr_tab">
                            <span><strong>登记表：</strong></span>
                            <el-button type="primary" size="small" plain
                              @click="toRouter('/user/register')">管理登记表</el-button>
                            <el-button type="primary" size="small" plain
                              @click="register_dialogVisible = true">查看完成情况</el-button><br><br>
                            <span><strong>收集表：</strong></span>
                            <el-button type="warning" size="small" plain
                              @click="toRouter('/user/collect')">管理收集表</el-button>
                            <el-button type="warning" size="small" plain
                              @click="collect_dialogVisible = true">查看完成情况</el-button><br>
                          </el-tab-pane>

                          <el-tab-pane label="管理工具" name="guanli_tab" v-if="usergroup != 3 && usergroup != 4">
                            <span>其他</span>
                            <div style="text-align: center;"><el-button type="primary" size="small" @click="toRouter('/user/notice')"
                                plain>公告管理</el-button>
                            <el-button type="primary" size="small" @click="toRouter('/user/votemgr')"
                                plain>投票管理</el-button>
                              <el-button type="primary" size="small" @click="toRouter('/user/qx')" v-if="usergroup == 1"
                                plain>账号管理</el-button>
                            </div>
                          </el-tab-pane>

                          <el-tab-pane label="收到的留言" name="liuyan_tab" v-if="usergroup != 3 && usergroup != 4">
                            <el-col :span="12">
                              <div>
                                <el-statistic title="共收到">
                                  <template slot="formatter">
                                    {{ liuyan_all_num }}
                                  </template>
                                </el-statistic>
                              </div>
                            </el-col>
                            <el-col :span="12">
                              <div>
                                <el-statistic title="未读">
                                  <template slot="formatter">
                                    {{ liuyan_unread_num }}
                                  </template>
                                </el-statistic>
                              </div>
                            </el-col>
                            <div style="text-align: center;"><el-button type="primary" size="small" @click="toRouter('/user/liuyan')"
                                plain>查看详情</el-button></div>
                          </el-tab-pane>

                          <el-dialog title="登记表完成情况" :visible.sync="register_dialogVisible" :width="reg_width">
                            <el-table :data="register_tableData" style="width: 100%">
                              <el-table-column prop="title" :show-overflow-tooltip="true" label="登记表标题"
                                min-width="160px"></el-table-column>
                              <el-table-column prop="finish_rate" label="完成率" min-width="80px"></el-table-column>
                              <el-table-column prop="finish_jd" label="完成进度" min-width="150px">
                                <template slot-scope="scope">
                                  <el-progress :text-inside="true" :stroke-width="17"
                                    :percentage="scope.row.finish_jd"></el-progress>
                                </template>
                              </el-table-column>
                            </el-table>
                            <span slot="footer" class="dialog-footer">
                              <el-button type="primary" @click="register_dialogVisible = false">确 定</el-button>
                            </span>
                          </el-dialog>
                          <el-dialog title="收集表完成情况" :visible.sync="collect_dialogVisible" :width="col_width">
                            <el-table :data="collect_tableData" style="width: 100%">
                              <el-table-column prop="title" :show-overflow-tooltip="true" label="收集表标题"
                                min-width="160px"></el-table-column>
                              <el-table-column prop="finish_rate" label="完成率" min-width="80px"></el-table-column>
                              <el-table-column prop="finish_jd" label="完成进度" min-width="150px">
                                <template slot-scope="scope">
                                  <el-progress :text-inside="true" :stroke-width="17"
                                    :percentage="scope.row.finish_jd"></el-progress>
                                </template>
                              </el-table-column>
                            </el-table>
                            <span slot="footer" class="dialog-footer">
                              <el-button type="primary" @click="collect_dialogVisible = false">确 定</el-button>
                            </span>
                          </el-dialog>
                        </el-tabs>
                      </div>
                    </el-collapse-item>
                    <el-collapse-item name="2">
                      <template slot="title">功能中心</template>
                      <div>
                        <div style="text-align: center;">
                          <el-button type="primary" size="small" style="margin: 2px" @click="toRouter('/user/list')"
                            plain>部门通讯录</el-button>
                          <el-button type="primary" size="small" style="margin: 2px" @click="toRouter('/user/random')"
                            plain>随机抽号</el-button><br>
                          <el-button type="primary" size="small" style="margin: 2px" @click="toRouter('/user/say')"
                            plain>我要留言</el-button>
                          <el-button type="primary" size="small" style="margin: 2px" @click="toRouter('/user/myScore')"
                            plain>我的成绩</el-button><br>
                          <el-button type="primary" size="small" style="margin: 2px" @click="toRouter('/user/vote')"
                            plain>投票</el-button>
                        </div>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </el-scrollbar>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
              <el-card class="box-card" v-loading="userinfo_loading">
                <div slot="header" class="clearfix">
                  <span>公告区</span>
                  <el-button style="float: right; padding: 3px 0" type="text">{{ now_time }}</el-button>
                </div>
                <div class="user_info_box_body" style="position: relative;">
                  <el-table v-loading="userinfo_loading"
                    :data="tableData.slice((currentPage - 1) * pagesize, currentPage * pagesize)" style="width: 100%">
                    <el-table-column :show-overflow-tooltip="true" label="公告标题" prop="title"
                      min-width="150px"></el-table-column>
                    <el-table-column label="发布时间" prop="time" min-width="160px" header-align="center" align="center">
                    </el-table-column>
                    <el-table-column label="操作" min-width="100px" header-align="center" align="center">
                      <template slot-scope="scope">
                        <el-button size="mini" @click="handleShow(scope.row)" width="100px">查看详情</el-button>
                      </template>
                    </el-table-column>
                  </el-table>

                  <!-- 表格分页 -->
                  <!-- pager-count pager-count属性可以设置最大页码按钮数,超出折叠,默认为7-->
                  <!-- 注意：若数据是后端接口返回的则此时:total="pageCount"-->
                  <el-pagination @current-change="handleCurrentChange" :pager-count="7" :current-page="currentPage"
                    :page-sizes="[3]" :page-size="pagesize" layout="total, prev, pager, next, jumper"
                    :total="tableData.length > 0 ? tableData.length : null" background
                    style="position: absolute; bottom: 0; right: 0;">
                  </el-pagination>
                </div>
                <el-dialog title="公告详情" :visible.sync="show_digital_dialog" :width="gonggao_width">
                  <span style="font-size:20px;">公告标题：{{ show_digital_array.title }}</span><br>
                  <span style="font-size:20px;">公告正文：{{ show_digital_array.content }}</span><br>
                  <span style="font-size:20px;">发布者：{{ show_digital_array.author }}</span><br>
                  <span style="font-size:20px;">发布时间：{{ show_digital_array.time }}</span><br>
                  <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="show_digital_dialog = false">确 定</el-button>
                  </span>
                </el-dialog>
              </el-card>
            </el-col>
            <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
              <el-card class="box-card" v-loading="userinfo_loading">
                <div slot="header" class="clearfix">
                  <div style="display:flex;justify-content : space-between;">
                    <span>备忘录</span>&nbsp;
                    <div style="height:32px;width:32px;">
                      <el-button type="primary" icon="el-icon-plus" circle @click="createBeiwanglu"></el-button>
                    </div>
                  </div>
                </div>
                <div class="user_info_box_body" style="padding:0;">
                  <el-empty description="这里空空如也~" v-if="memorandumData.length === 0"></el-empty>
                  <el-scrollbar style="height:100%">
                    <div v-for="(item, index) in memorandumData" :key="index" class="text item" style="padding:3px">
                      <el-card class="box-card" style="box-shadow: none;border">
                        <el-row>
                          <el-col :span="20">
                            <div style="width:80%;">{{ item.title }}</div>
                          </el-col>
                          <el-col :span="4"><el-button type="danger" icon="el-icon-delete" circle
                              @click="delBeiwanglu(item.id)"></el-button></el-col>
                        </el-row>
                      </el-card>
                    </div>
                  </el-scrollbar>
                </div>
              </el-card>
              <el-dialog title="创建备忘录" :visible.sync="create_beiwanglu_dialog" :width="beiwanglu_width">
                <span style="text-align:left;font-size: 16px;font-weight: 700;">备忘录内容</span><br>
                <el-input type="textarea" :rows="5" maxlength="50" show-word-limit v-model="beiwanglu.content"
                  placeholder="请输入创建的备忘录内容，不可超过50字"></el-input>
                <br><br><span style="text-align:left;font-size: 16px;font-weight: 700;">验证码</span><br>
                <el-input v-model="beiwanglu.yz_code" placeholder="请输入验证码（不区分大小写）">
                  <template slot="append">
                    <div @click="refreshCode">
                      <s-identify :identifyCode="identifyCode"></s-identify>
                    </div>
                  </template>
                </el-input>
                <span slot="footer" class="dialog-footer">
                  <el-button type="primary" @click="postBeiwanglu">创 建</el-button>
                  <el-button @click="cancelBeiwanglu">取 消</el-button>
                </span>
              </el-dialog>
            </el-col>
          </el-row>
          <el-row :gutter="20" style="padding-top:15px">
            <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
              <el-card class="box-card" v-loading="task_loading">
                <div slot="header" class="clearfix">
                  <span>任务列表</span>
                  <el-tooltip class="item" effect="dark" content="仅展示最近5条数据，查看全部数据请前往任务中心" placement="top">
                    <i class="el-icon-info" style="font-size: 15px;"></i>
                  </el-tooltip>
                  <el-button style="float: right; padding: 3px 0" @click="toRouter('/user/mytask')"
                    type="text">前往任务中心</el-button>
                </div>
                <div class="user_info_box_body">
                  <el-empty description="这里空空如也~" v-if="tasklist.length === 0"></el-empty>
                  <el-scrollbar style="height:100%">
                    <el-collapse accordion>
                      <el-collapse-item v-for="(item, index) in tasklist" :key="index">
                        <template slot="title">
                          {{ item.title }}&nbsp;&nbsp;<el-tag :type="item.if_finish == true ? 'success' : 'danger'"
                            size="small">{{ item.if_finish == true ? '已完成' : '未完成' }}</el-tag>
                        </template>

                        <div>任务类型：<el-tag size="mini">{{ item.leixing == 1 ? '登记表' : '收集表' }}</el-tag>
                        </div>
                        <div>发布日期：{{ item.post_time }}</div>
                        <div v-if="item.iflimit == 'false'">截止时间：{{ item.jiezhi_time }}</div>
                        <div v-if="item.iflimit == 'true'">起止时间：{{ item.start_time }} 至 {{ item.end_time }}</div>
                        <div>状态：
                          <el-tag v-if="item.status == 1" type="primary" size="mini" plain>未开始</el-tag>
                          <el-tag v-if="item.status == 2" type="success" size="mini" plain>进行中</el-tag>
                          <el-tag v-if="item.status == 3" type="danger" size="mini" plain>已结束不可提交</el-tag>
                          <el-tag v-if="item.status == 4" type="warning" size="mini" plain>已结束可提交</el-tag>
                        </div>
                        <div>发布人：{{ item.poster }}</div>
                        <div>操作：
                          <el-button size="mini" type="info" v-if="item.status === 1">不可完成</el-button>
                          <el-button size="mini" type="success" v-else-if="item.if_finish === true"
                            @click="item.leixing == 1 ? toRouter('/user/finish_register/' + item.unid) : toRouter('/user/collect_file/' + item.unid)">查看详情</el-button>
                          <el-button size="mini" type="info" v-else-if="item.status === 3">不可完成</el-button>
                          <el-button size="mini" type="danger" v-else-if="item.if_finish === false"
                            @click="item.leixing == 1 ? toRouter('/user/finish_register/' + item.unid) : toRouter('/user/collect_file/' + item.unid)">前往完成</el-button>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </el-scrollbar>
                </div>
              </el-card>
            </el-col>
            <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
              <el-card class="box-card" v-loading="task_loading">
                <div slot="header" class="clearfix">
                  <span>任务完成情况</span>
                </div>
                <div id="task_finish_rate" style="width: 100%; height: 270px"></div>
              </el-card>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-dialog title="修改密码" :visible.sync="change_passwd" :width="reg_width" :before-close="handleClose">
        <h4 style="text-align: center;">系统检测到你还没有修改过密码，为了账号安全，请尽快修改默认密码！</h4><br>
        <el-form ref="pass_form" :model="pass_form" :rules="pass_rules" label-width="80px">
          <el-form-item label="新密码" prop="pass">
            <el-input type="password" ref="newPasswd" v-model="pass_form.pass" placeholder="请输入修改的密码（不修改请留空）"
              show-password autocomplete="off"></el-input>
            <div class="input_span">
              <span
                :style="{ 'background-color': (msgText > 1 || msgText == 1) ? '#FC5F76' : '#BBBBBB', 'color': (msgText > 1 || msgText == 1) ? '#FC5F76' : '#BBBBBB' }">弱</span>
              <span
                :style="{ 'background-color': (msgText > 2 || msgText == 2) ? '#FF9900' : '#BBBBBB', 'color': (msgText > 2 || msgText == 2) ? '#FF9900' : '#BBBBBB' }">中</span>
              <span
                :style="{ 'background-color': (msgText > 4 || msgText == 4) ? '#33CC00' : '#BBBBBB', 'color': (msgText > 4 || msgText == 4) ? '#33CC00' : '#BBBBBB' }">强</span>
            </div>
            <span>新密码至少8个字符,必须同时包含字母、数字！</span>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input type="password" ref="rePasswd" v-model="pass_form.checkPass" placeholder="请再次输入新密码" show-password
              autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="onSubmit_ChangePassWord('pass_form')">确认修改</el-button>
          <el-button @click="handleClose">暂不修改</el-button>
        </span>
      </el-dialog>
    </el-main>
  </el-main>
</template>

<style scoped>
.breadcrumb_box {
  height: 40px;
  width: 100%;
  background-color: #f6f8f8;
  border-bottom: 1px solid #eee;
  padding: 15px;
  margin: 0;
  display: flex;
}

.breadcrumb_content {
  font-size: 15px;
  position: absolute;
  margin: auto;
  left: 40px;
}

.user_info_box {
  height: 450px;
  width: 100%;
  background-color: #FFF;
  border-bottom: 1px solid #EBEEF5;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}

.user_info_box_body {
  height: 270px;
  width: 100%;
  padding: 0px;
}

.avatar_img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  padding: 4px;
  margin: 15px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  vertical-align: middle;
  width: 66px;
  border-radius: 50%;
}

.clearfix {
  font-size: 19px;
}

.tuanyuan_box {
  height: 100%;
  width: 100%;
  margin: 5px 0 5px 0;
  padding: 5px 0 5px 0;
  border: #EBEEF5 solid 1px;
  border-radius: 5px;
}

.shenhe_box {
  height: 100%;
  width: 100%;
  margin: 5px 0 5px 0;
  padding: 5px 0 5px 0;
  border: #EBEEF5 solid 1px;
  border-radius: 5px;
}

.input_span {
  height: 8px;
  display: flex;
  float: right;
  width: 35%;
}

.input_span span {
  display: inline-block;
  width: 30%;
  border-radius: 8px;
  margin-right: 3px;
  text-align: center;
  margin-top: 3px;
}
</style>
<style>
.el-input-group__append,
.el-input-group__prepend {
  padding: 0 !important;
}
.el-collapse-item__header {
  line-height: 16px !important;
}

</style>

<script>
import axios from 'axios'
import SIdentify from '@/components/SIdentify'
export default {
  components: { SIdentify },
  name: "UserCenter",
  data() {
    let year = new Date().getFullYear();
    let month = new Date().getMonth() + 1;
    let day = new Date().getDate();

    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.pass_form.checkPass !== '') {
          this.$refs.pass_form.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.pass_form.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };

    return {
      msgText: "",

      ty_num: 'NaN',
      all_num: 'NaN',
      baoxiao_all_num: 'NaN',
      baoxiao_unfinish_num: 'NaN',
      liuyan_all_num: 'NaN',
      liuyan_unread_num: 'NaN',

      usergroup: 3,
      zhiwu: '',
      class_name: '',

      gonggao_width: '50%',
      reg_width: '50%',
      col_width: '50%',
      beiwanglu_width: '40%',

      username: localStorage.getItem('name'),
      unread_num: 0,
      adminPanelLoading: true,
      userinfo_loading: true,
      task_loading: true,
      create_beiwanglu_dialog: false,
      show_digital_array: [],
      show_digital_dialog: false,
      register_dialogVisible: false,
      change_passwd: false,
      collect_dialogVisible: false,
      now_time: year + "年" + month + "月" + day + "日",
      tool_box: ['1', '2'],
      banwei_tool: 'taskmgr_tab',
      register_tableData: [],
      collect_tableData: [],
      tasklist: [],
      tableData: [],
      beiwanglu: {
        content: '',
        yz_code: ''
      },
      memorandumData: [],
      rules: {
        content: [
          { required: true, message: '备忘录内容', trigger: 'blur' }
        ],
      },

      pass_form: {
        pass: '',
        checkPass: ''
      },

      pass_rules: {
        pass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
      },

      currentPage: 1,  //默认初始页
      pagesize: 4,    //每页默认显示的数据
      pageCount: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改

      total_task: 0,
      finish_task: 0,

      identifyCode: '',
      identifyCodes: '1234567890abcdefjhijklinopqrsduvwxyz',
    };
  },
  methods: {
    drawChart() {
      let myChart = this.$echarts.init(document.getElementById("task_finish_rate"));
      axios.get('/api/getTaskList.php', { params: { method: 'limit' } })
        .then(res => {
          this.tasklist = res.data.tasklist
          this.task_loading = false

          this.total_task = res.data.total
          this.finish_task = res.data.finish

          let option = {
            title: {
              text: '完成情况',
              left: 'center',
              top: 'center'
            },
            series: [
              {
                type: 'pie',
                data: [
                  {
                    value: this.finish_task,
                    name: '已完成'
                  },
                  {
                    value: this.total_task - this.finish_task,
                    name: '未完成'
                  }
                ],
                color: [
                  '#50C878',
                  '#fb7293'
                ],
                radius: ['40%', '70%']
              }
            ],
            tooltip: {
              show: true,
              trigger: 'item'
            }
          };
          myChart.setOption(option);
          window.addEventListener('resize', () => {
            myChart.resize()
          })

        }).catch(err => {
          console.log(err)
        })

    },
    checkStrong(sValue) {
      var modes = 0;
      //正则表达式验证符合要求的
      if (sValue.length < 1) return modes;
      if (/\d/.test(sValue)) modes++;
      if (/[a-z]/.test(sValue)) modes++;
      if (/[A-Z]/.test(sValue)) modes++;
      if (/\W/.test(sValue)) modes++;
      switch (modes) {
        case 1:
          return 1;
          break;
        case 2:
          return 2;
          break;
        case 3:
        case 4:
          return sValue.length < 4 ? 3 : 4;
          break;
      }
      return modes;
    },
    handleClose(done) {
      this.$confirm('确认关闭修改密码窗口吗？非常建议修改初始密码~', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(_ => {
          this.$refs['pass_form'].resetFields();
          this.pass_form = {
            pass: '',
            checkPass: ''
          };
          this.change_passwd = false;
          done();
        })
        .catch(_ => { });
    },
    //切换页码
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
    },
    //切换页码
    handleCurrentChange_liuyan: function (currentPage) {
      this.currentPage_liuyan = currentPage;
    },
    handleShow(row) {
      this.show_digital_dialog = true
      this.show_digital_array = row
    },
    toRouter(url) {
      this.$router.push(url)
    },
    createBeiwanglu() {
      this.create_beiwanglu_dialog = true
    },
    toFinishRegisterTask(id) {
      this.$router.push('/user/finish_register/' + id);
    },
    toCollectRegisterTask(id) {
      this.$router.push('/user/collect_file/' + id);
    },
    refreshCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)]
      }
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    getHomeAdminData() {
      axios.get('/api/getHomeAdminData.php')
        .then(res => {

          this.register_tableData = res.data.reg
          this.collect_tableData = res.data.col
          this.ty_num = res.data.ty.ty_num
          this.all_num = res.data.ty.all_num
          this.baoxiao_all_num = res.data.sh.all_num
          this.baoxiao_unfinish_num = res.data.sh.unfinish_num
          this.liuyan_all_num = res.data.ly.all_num
          this.liuyan_unread_num = res.data.ly.unread_num

          this.adminPanelLoading = false

        }).catch(err => {
          console.log(err)
        })
    },
    getHomeUserData() {
      axios.get('/api/getHomeUserData.php')
        .then(res => {
          if (res.data.code != null && res.data.code == 400) {
            this.$message.error('登录状态过期，请重新登录');
            localStorage.clear('name');
            localStorage.clear('isLogin');
            this.$router.push('/login?refer=' + window.location.pathname)
          }
          else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

          this.unread_num = res.data.unreadnum
          this.memorandumData = res.data.beiwanglu
          this.class_name = res.data.class
          this.tableData = res.data.notice
          this.pageCount = res.data.notice.length
          this.usergroup = res.data.usergroup
          this.zhiwu = res.data.zhiwu
          this.change_passwd = res.data.change_passwd

          this.userinfo_loading = false

          if (this.usergroup != 3) this.$options.methods.getHomeAdminData.bind(this)();
          else this.adminPanelLoading = false
        }).catch(err => {
          console.log(err)
        })
    },
    postBeiwanglu() {
      if (this.beiwanglu.content == '') {
        this.$message.error('内容不可为空');
      } else if (this.beiwanglu.yz_code != this.identifyCode) {
        this.$message.error('验证码错误');
        this.identifyCode = '';
        this.makeCode(this.identifyCodes, 4);
      }
      else {
        this.axios({
          method: "post",
          url: '/api/MemorandumController.php?method=add',
          data: {
            content: this.beiwanglu.content
          },
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message.success('添加成功！');
            this.memorandumData.unshift({ id: res.data.addid, title: this.beiwanglu.content })

            this.create_beiwanglu_dialog = false

            this.beiwanglu.content = ''
            this.beiwanglu.yz_code = ''
            this.identifyCode = '';
            this.makeCode(this.identifyCodes, 4);
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    cancelBeiwanglu() {
      this.create_beiwanglu_dialog = false
      this.beiwanglu.yz_code = ''
      this.beiwanglu.content = ''
      this.identifyCode = '';
      this.makeCode(this.identifyCodes, 4);
    },
    delBeiwanglu(id) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.get('/api/MemorandumController.php', { params: { method: 'del', id: id } })
          .then(res => {
            if (res.data.code != null && res.data.code == 400) {
              this.$message.error('登录状态过期，请重新登录');
              localStorage.clear('name');
              localStorage.clear('isLogin');
              this.$router.push('/login?refer=' + window.location.pathname)
            }

            if (res.data.code == 200) {
              this.$message.success('删除成功！');
              for (var i = 0; i < this.memorandumData.length; i++) {
                if (this.memorandumData[i].id == id) {
                  this.memorandumData.splice(i, 1);
                  break;
                }
              }
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(err => {
            console.log(err)
          })
      });
    },
    onSubmit_ChangePassWord(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var pwd = this.pass_form.pass
          var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{8,30}')
          if (!pwdRegex.test(pwd)) {
            this.$message.error("密码强度不符合要求！");
            this.$refs.newPasswd.focus();
            return false;
          }
          else {
            let data = new FormData();
            data.append('newpasswd', pwd);

            this.axios({
              method: "post",
              url: '/api/getUserData.php?method=change',
              data: data,
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            })
              .then(res => {
                if (res.data.code != null && res.data.code == 400) {
                  this.$message.error('登录状态过期，请重新登录');
                  localStorage.clear('name');
                  localStorage.clear('isLogin');
                  this.$router.push('/login?refer=' + window.location.pathname)
                }
                else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

                if (res.data.code == 200) {
                  this.$message.success('密码修改成功！');
                  this.$set(this.pass_form, 'pass', null);
                  this.$set(this.pass_form, 'checkPass', null);
                  this.change_passwd = false;
                }
              }).catch(err => {
                console.log(err)
              })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleResize() {
      if (window.innerWidth < 992) {
        this.gonggao_width = "100%"
        this.reg_width = "100%"
        this.col_width = "100%"
        this.beiwanglu_width = "100%"
      } else {
        this.gonggao_width = "50%"
        this.reg_width = "50%"
        this.col_width = "50%"
        this.beiwanglu_width = "40%"
      }
    }
  },
  mounted() {
    this.drawChart();
    this.identifyCode = '';
    this.makeCode(this.identifyCodes, 4);
    this.handleResize()
    window.addEventListener("resize", this.handleResize);
  },
  created() {
    this.userinfo_loading = true
    this.adminPanelLoading = true
    this.getHomeUserData();
  },
  watch: {
    'pass_form.pass': {
      handler(keyWord, oldname) {
        this.msgText = this.checkStrong(keyWord);
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>
